import  React, { useState } from 'react';
import { MinusIcon, PlusIcon } from '../assets/icons/Icons';


const faqs = [
  {
    id: 1,
    header: "Do I need a licence to sell on Etsy?",
    text: `No. Not necessarily. Etsy is an online open marketplace that focuses on handcrafted and vintage goods. Though anyone can sell on Etsy, you must check the etsy rules related to selling online.`,
  },
  {
    id: 2,
    header: "Is Etsy Profitable?",
    text: `There are two reasons for saying ‘Yes’ in answer to this question-
Etsy provides a greater exposure to the people who are in search of a relevant platform upon which they can display their creativity.

Etsy is compatible with platforms like Shopify, so one can easily sell products from his Shopify store on Etsy.  
    `,
  },
  {
    id: 3,
    header: "Is selling on Etsy free of cost?",
    text: `Joining and starting a shop on Etsy is free . Then there is a minimal listing fee, transaction fee, order processing fee and a small commission fee.
For instance-
$0.2 Listing charges

5% transaction fee`,
  },
  {
    id: 4,
    header: "Is Listing Products on Etsy difficult?",
    text: `Listing products on Etsy involves general set-up steps . With CedCommerce Etsy Integration app and other Product Lister Services, listing products on Etsy has become quite easy and seamless.
`,
  },
];

const FaqsAccordion = () => {
      const [activeIndex, setActiveIndex] = useState(null);

      const handleToggle = (index:any) => {
        setActiveIndex(activeIndex === index ? null : index);
      };
    
  return (
      <div className="ced-faq-content">
          {faqs.map((faq, index) => (
            <div key={faq.id} className="ced-faq-item">
              <div
                className={`ced-faq-header ${activeIndex === index ? 'open' : ''}`}
                onClick={() => handleToggle(index)}
              >
                <h3>{faq.header}</h3>
                
                  {activeIndex === index ? MinusIcon
                  : PlusIcon
                  }
                

              </div>
              <div
                className={`ced-faq-text ${activeIndex === index ? 'open' : ''}`}
                style={{
                  height: activeIndex === index ? 'auto' : '0',
                }}
              >
                {faq.text}
              </div>
            </div>
          ))}
        </div>
  )
}

export default FaqsAccordion
