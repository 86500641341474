import React from "react";

const UpdationInPersonalInfo = () => {
  return (
    <div>
        <h2>Updation in Personal Information / Inquiries / Complaints</h2>
        <p>
        In case you want any changes in the information you could mail us at <a  href="mailto:apps@cedcommerce.com" target="_blank" rel="noopener">apps@cedcommerce.com</a>. Alternatively, you could write us at :
        </p>
        <div>
            <p>CedCommerce Inc</p>
            <p>3/460 Vishwas Khand</p>
            <p>Gomti Nagar</p>
            <p>Lucknow – 226010</p>
            <p>U.P, India.</p>
        </div>
    </div>
  )
}

export default UpdationInPersonalInfo
