import React, { useState } from "react";
import {
  CheckIcon,
  ChevronDown,
  ChevronUp,
} from "../assets/icons/Icons";
import CustomSlider from "./CustomSlider";
import { usePublicAppContext } from "../contexts/AppProvider";

const PricingCards = ({ selected }: any) => {
  const { plansState } = usePublicAppContext();
  const [expandedCards, setExpandedCards] = useState<number[]>([]); // Track expanded cards
  const [rangeValue, setRangeValue] = useState(0);
  const filteredData = plansState.plans.filter(
    (plan) => plan.billed === selected.toLowerCase()
  );
  const displayFeaturesCount = 7;

  const toggleCardExpansion = (id: number) => {
    setExpandedCards((prev) =>
      prev.includes(id) ? prev.filter((cardId) => cardId !== id) : [...prev, id]
    );
  };

  const getPriceValue = (planItem: any) => {
    let price = planItem?.id?.includes("dynamic")
      ? planItem?.priceValues[rangeValue]
      : planItem?.price;
    if (plansState.yearlyDiscount && planItem?.billed === "yearly") {
      return Math.floor(price - (plansState.yearlyDiscount / 100) * price);
    }
    return price;
  };

  return (
    <div className="ced-pricing-plan-wrapper">
      {filteredData.map((item) => {
        const isExpanded = expandedCards.includes(item.id);
        const features = item?.id?.includes("dynamic")
          ? item?.featuresValues[rangeValue]
          : item?.features;
        const visibleFeatures: string[] = isExpanded
          ? features
          : features?.slice(0, displayFeaturesCount);

        return (
          <div key={item.id} className="ced-pricing-card">
            <div className="ced-pricing-card-header">
              <p className="ced-pricing-plan-name">{item.name}</p>
              <div className="ced-pricing-plan-price">
                <span className="ced-plan-price">
                  $
                  {selected.toLowerCase() === "monthly"
                    ? getPriceValue(item)
                    : (getPriceValue(item) / 12).toFixed(2)}
                </span>
                <span className="ced-plan-type">/ month</span>
              </div>
              <div className="ced-pricing-optional-box">
                {item?.id?.includes("dynamic") && (
                  <div className="ced-pricing-card-range">
                    <span className="ced-order-per-month">Order/month</span>
                    <CustomSlider
                      orderCounts={item?.orderCounts}
                      rangeValue={rangeValue}
                      setRangeValue={setRangeValue}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="ced-pricing-card-body">
              {visibleFeatures?.map((feature, index) => (
                <div key={index} className="ced-pricing-card-feature">
                  {CheckIcon}
                  <p>{feature}</p>
                </div>
              ))}

              {features?.length > displayFeaturesCount && (
                <button
                  title={
                    isExpanded ? "View Less Features" : "View More Features"
                  }
                  className="ced-btn ced-btn-plain"
                  onClick={() => toggleCardExpansion(item.id)}
                >
                  {isExpanded ? "View Less Features" : "View More Features"}
                  <span>{isExpanded ? ChevronUp : ChevronDown}</span>
                </button>
              )}
            </div>
            <div className="ced-pricing-card-footer">
              <button
                title="Install Now"
                onClick={() => {
                  window.open(
                    "https://apps.shopify.com/etsy-marketplace-integration"
                  );
                }}
                className="ced-btn ced-btn-primary"
              >
                Install Now
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PricingCards;
