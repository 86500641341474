import { IndexTable, InlineStack, Text } from "@shopify/polaris";
import React, { useMemo } from "react";
import { DI } from "Src/core";
import { useBulkEditContext } from "./BulkEditProvider";
import ImageAndTitle from "./Components/ImageAndTitle";
import VendorData from "./Components/VendorData";
import InventoryData from "./Components/InventoryData";
import PriceData from "./Components/PriceData";
import WeightData from "./Components/WeightData";
import DimensionsData from "./Components/DimensionsData";
import TagsData from "./Components/TagsData";
import ShopSection from "./Components/ShopSection";
import BulkSkeletonRowMarkup from "./Skeleton/BulkSkeletonRowMarkup";
import { DIProps } from "Src/Interface/@core";
export const Empty_Line = <div className="thick-line"></div>;
const AllBulkRows = ({ t }: DIProps) => {
  const { bulkproduct, loading } = useBulkEditContext();
  const rowMarkup = useMemo(
    () =>
      Object.values(bulkproduct).map(
        (
          {
            product_id,
            images,
            title,
            vendor,
            variants,
            dimensions,
            tags,
            etsy_attribute,
          }: any,
          index: number
        ) => (
          <React.Fragment key={index + product_id}>
            <IndexTable.Row id={product_id} position={index}>
              <ImageAndTitle images={images} title={title} id={product_id} />
              <TagsData tags={tags} id={product_id} />
              <DimensionsData dimensions={dimensions} id={product_id} />
              <VendorData vendor={vendor} />

              <ShopSection etsy_attribute={etsy_attribute} id={product_id} />
              <IndexTable.Cell flush className="min-w-120 bg-white ">
                {Empty_Line}
              </IndexTable.Cell>
              <IndexTable.Cell flush className="min-w-120 bg-white ">
                {Empty_Line}
              </IndexTable.Cell>
              <IndexTable.Cell flush className="min-w-160 bg-white ">
                {Empty_Line}
              </IndexTable.Cell>
            </IndexTable.Row>

            {Object.values(variants).map(
              (
                {
                  variant_title,
                  variant_id,
                  sku,
                  price,
                  weight,
                  inventory,
                  weight_unit,
                }: any,
                index: any
              ) => (
                <IndexTable.Row
                  id={product_id}
                  key={index + variant_id + product_id}
                  position={index}
                >
                  <IndexTable.Cell
                    flush
                    className="bg-color-subdued  variant-cell"
                  >
                    <InlineStack wrap={false}>
                      <div className="variant-title-cell">
                        <Text
                          variant="bodyMd"
                          as="span"
                          fontWeight="medium"
                          truncate
                        >
                          {variant_title}
                        </Text>
                      </div>
                      <div className="variant-sku-cell">
                        <Text
                          variant="bodyMd"
                          as="span"
                          fontWeight="medium"
                          truncate
                        >
                          {t("SKU")}: {sku}
                        </Text>
                      </div>
                    </InlineStack>
                  </IndexTable.Cell>
                  <IndexTable.Cell flush className="bg-color-subdued">
                    {Empty_Line}
                  </IndexTable.Cell>
                  <IndexTable.Cell flush className="bg-color-subdued">
                    {Empty_Line}
                  </IndexTable.Cell>
                  <IndexTable.Cell flush className=" bg-color-subdued">
                    {Empty_Line}
                  </IndexTable.Cell>
                  <IndexTable.Cell flush className=" bg-color-subdued">
                    {Empty_Line}
                  </IndexTable.Cell>
                  <IndexTable.Cell flush className=" bg-color-subdued">
                    <InventoryData
                      inventory={inventory}
                      id={product_id}
                      variant_id={variant_id}
                    />
                  </IndexTable.Cell>
                  <IndexTable.Cell flush className="bg-color-subdued">
                    <PriceData
                      price={price}
                      id={product_id}
                      variant_id={variant_id}
                    />
                  </IndexTable.Cell>
                  <IndexTable.Cell flush className=" bg-color-subdued">
                    <WeightData
                      weight={weight}
                      unit={weight_unit}
                      id={product_id}
                      variant_id={variant_id}
                    />
                  </IndexTable.Cell>
                </IndexTable.Row>
              )
            )}
          </React.Fragment>
        )
      ),
    [bulkproduct]
  );

  if (loading) {
    return <BulkSkeletonRowMarkup />;
  }
  return rowMarkup;
};

export default DI(AllBulkRows);
