import { Link, useLocation } from 'react-router-dom'
import logo from '../assets/logo.svg'
import React, { useState } from "react";
import useWindowResize from '../utils/useWindowResize';
import { CrossIconMark, HamburgurIcon } from '../assets/icons/Icons';
import useBodyLock from '../utils/UseBodyLock';

const Navbar = () => {
  const width = useWindowResize();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);


  useBodyLock(isMobileMenuOpen === true);


  return (
    <header className="ced-header-section">
      <div className="ced-header__inner ced-container">
        <div className="ced-header__logo">
          <Link to="/public" className="ced-header__logo-link"> <img src={logo} alt="CedCommerce" title="CedCommerce" width={200} height={52} /></Link>
        </div>
        <nav className={`ced-header__nav ${isMobileMenuOpen ? 'ced-menu-active' : ''}`}>
          {width.width <= 991 && <span
            role="button"
            className='ced-header__close'
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            {CrossIconMark}
          </span>}
          <ul className="ced-header__nav-list">
            <li className="ced-header__nav-item">
              <Link className={`ced-header__nav-link ${location.pathname === '/public' ? 'active' : ''}`} to="/public" onClick={() => setIsMobileMenuOpen(false)}>Pricing</Link>
            </li>
            <li className="ced-header__nav-item">
              <Link className={`ced-header__nav-link ${location.pathname === '/public/privacy-policy' ? 'active' : ''}`} to="/public/privacy-policy" onClick={() => setIsMobileMenuOpen(false)}>Privacy Policy</Link>
            </li>
          </ul>
        </nav>
        {width.width <= 991 &&
          <span
            role="button"
            className="ced-header__toggle-button"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-label="Toggle navigation"
          >
            {HamburgurIcon}
          </span>
        }
      </div>

      {isMobileMenuOpen && (
        <div
          onClick={() => setIsMobileMenuOpen(false)}
          className="ced-header__backdrop"
        />
      )}
    </header>
  )
}

export default Navbar
