import React from "react";

const HowToDeal = () => {
  return (
    <div>
        <h2>
        How do we deal with 3rd Parties?
        </h2>
        <p>
        Any associated affiliate may receive the personal information shared and they may contact you on offering their solution and services. Associated Partners will receive the necessary information from the clients in order to reach out to them to offer the complete service solution. We don’t allow any 3rd Parties to conduct contests and surveys and even if we do then we may prohibit them from using the information for any other purpose In the event of the sale of business all the information would be transferred but before that information would be shared with you related to the same. We may conduct advertisements and use third-party tools and share information including name, address, email address, and telephone number to show tailored-based advertisements of products that may suit your purpose. We reserve the right to disclose your personal information as required by law, and as necessary to protect the property, interests, and rights of CedCommerce and the CedCommerce Affiliates. The CedCommerce website might contain links to the 3rd Parties Websites. Those 3rd Party Websites may use cookies in order to gather information. This privacy policy does not cover their privacy setup. So we suggest going through each site's privacy policy separately. We will use third-party software like MailChimp, Hot Jar, Live Chat Facilitators, Google Analytics, and Google Adwords to track the visits of the prospects and for remarketing and retargeting purposes.
        </p>
    </div>
  )
}

export default HowToDeal
