import { Tooltip } from "@shopify/polaris";
import React from "react";

interface PropsI {
  child: JSX.Element;
  text: string;
  enabledParent?: boolean;
}

const WithTogglabbleTooltip = ({
  child,
  text,
  enabledParent = false,
}: PropsI) => {
  
  return enabledParent ? <Tooltip content={text}>{child}</Tooltip> : child;
};

export default WithTogglabbleTooltip;
