import React from 'react';
import hero from '../assets/hero.png'
import { DarkArrow } from '../assets/icons/Icons'
import shopify from '../assets/shopify.png'
const Hero = () => {

  return (
    <section className="ced-hero ced-container ced-section-space">
      <div className="ced-hero__inner">
        <div className="ced-hero__content">
          <h1 className="ced-hero__title">Make Etsy Selling Smarter and Faster 
          </h1>
          <p className="ced-hero__subtitle">Amplify your Etsy success with enhanced product visibility and greater brand reach. Designed for systematic product management, this is the ultimate solution to streamline Etsy operations.
          </p>
          <button onClick={() => {
            window.open(
              "https://apps.shopify.com/etsy-marketplace-integration",
            );
          }} title='Install Now' className="ced-btn ced-hero__cta ced-animate-action"><img width={28} height={32} src={shopify} alt="shopify" /> Install Now <span className='ced-arrow-icon'><DarkArrow size={40} /></span></button>

          {/* <div className='ced-input-wrapper'><input type='text' placeholder="example.myshopify.com"/>
            <button onClick={() => {}} title='Login' className="ced-login-btn">Login</button>
          </div> */}
        </div>
        <div className="ced-hero__image">
          <img loading='lazy' src={hero} alt='Boost Your Sales On Etsy' width={554} height={435} />
        </div>
      </div>
    </section>
  )
}

export default Hero
