import {
  Banner,
  BlockStack,
  Box,
  Button,
  Icon,
  InlineStack,
  ProgressBar,
  Text,
  TextField,
} from "@shopify/polaris";
import React, { useState } from "react";
import { apiUrls } from "Src/Constant/url";
import { DI } from "Src/core";
import { DIProps } from "Src/Interface/@core";
import Status from "../Dashboard/components/Status";
import ModalAppBridge from "../HelperComponents/ModalAppBridge";
import { ArrowRightIcon } from "@shopify/polaris-icons";
import { capitalizeWords } from "../HelperComponents/GlobalFunctions";
import "../Dashboard/dashboard.css";
import { getFormattedDateTime } from "Src/function";
import { ToastAppBridge } from "../HelperComponents/Toast";

const { abortProcess } = apiUrls;

const RunningActivity = ({
  ongoing_activity,
  request: { POST },
  globalState: { get },
  t,
}: DIProps & { ongoing_activity: any }) => {
  const [abortModal, setAbortModal] = useState<string | undefined>(undefined);
  const [aborttext, setAborttext] = useState("");
  const [abortLoading, setAbortLoading] = useState(false);

  const handleAbort = () => {
    setAbortLoading(true);
    POST(abortProcess, {
      id: abortModal,
      shop_url: get("shop"),
    })
      .then((e) => {
        if (e.success) {
          setAborttext("");
          setAbortModal(undefined);
        }
        ToastAppBridge(e?.message, { isError: !e?.success });
      })
      .finally(() => {
        setAbortLoading(false);
      });
  };
  return (
    <>
      {ongoing_activity?.length > 0 &&
        ongoing_activity.map((ongoingActivity: any) => (
          <div className="notification-item" key={ongoingActivity.id}>
            <Box padding="0" borderRadius="200">
              <InlineStack wrap={false} gap="200" blockAlign="start">
                <Status type={"warning"} />
                <div style={{ flex: 1 }}>
                  <BlockStack gap={"300"}>
                    <BlockStack gap="200">
                      <InlineStack align="space-between">
                        <Text as="p" variant="bodyLg" fontWeight="medium">
                          {capitalizeWords(ongoingActivity.title || "--")}
                        </Text>
                      </InlineStack>
                      <Text as="p" variant="bodyMd" tone="subdued">
                        {ongoingActivity.message}
                      </Text>
                      {ongoingActivity?.options?.infinite_weightage ? (
                        <BlockStack gap="100">
                          <div className="infinite-progress-bar">
                            <ProgressBar
                              tone="primary"
                              size="small"
                              progress={100}
                            />
                          </div>
                          <Text tone="subdued" as="span">{`Success count : ${
                            ongoingActivity?.options?.success_count ?? 0
                          } ${
                            ongoingActivity?.options?.error_count
                              ? `| Error count : ${ongoingActivity?.options?.error_count}`
                              : ""
                          }`}</Text>
                        </BlockStack>
                      ) : (
                        <ProgressBar
                          tone="primary"
                          progress={ongoingActivity.progress}
                          size="small"
                        />
                      )}
                      <InlineStack
                        gap="100"
                        align="start"
                        blockAlign="start"
                        wrap={false}
                      >
                        <Text tone="disabled" as="p" fontWeight="medium">
                          {getFormattedDateTime(
                            ongoingActivity.created_at,
                            true
                          )}
                        </Text>
                        <span>
                          <Icon source={ArrowRightIcon} tone="subdued" />
                        </span>
                        <Box minWidth="90px">
                          <Text as="p" variant="bodyMd" tone="caution">
                            {t("IN_PROGRESS")}...
                          </Text>
                        </Box>
                      </InlineStack>
                    </BlockStack>
                    <InlineStack align="start">
                      <Button
                        tone="critical"
                        variant="plain"
                        onClick={() => setAbortModal(ongoingActivity.id)}
                      >
                        {t("ABORT_ACTIVITY")}
                      </Button>
                    </InlineStack>
                  </BlockStack>
                </div>
              </InlineStack>
            </Box>
          </div>
        ))}

      {abortModal !== undefined && (
        <ModalAppBridge
          id={"abort-modal"}
          title={"Abort ongoing activity"}
          footer={
            <>
              <button
                tone="critical"
                variant="primary"
                disabled={aborttext.trim() !== "abort"}
                onClick={handleAbort}
                loading={abortLoading && "true"}
              >
                {t("ABORT")}
              </button>
              <button
                onClick={() => {
                  setAborttext("");
                  setAbortModal(undefined);
                }}
              >
                {t("CANCEL")}
              </button>
            </>
          }
          onHide={() => {
            setAborttext("");
            setAbortModal(undefined);
          }}
          body={
            <Box padding={"400"}>
              <BlockStack gap={"400"}>
                <Banner tone="info">{t("ABORT_BANNER_1")}</Banner>
                <BlockStack gap={"500"}>
                  <Text variant="bodyMd" as="p">
                    {t("ABORT_BANNER_2")}
                  </Text>
                  <Text variant="bodyMd" as="p">
                    {t("CNF_ABORT_MSG")}
                  </Text>
                  <TextField
                    autoComplete="off"
                    onChange={setAborttext}
                    value={aborttext}
                    label={
                      <Text variant="bodyMd" as="p">
                        {t("TERMINATE_THE_PROCESS_ABORT")}{" "}
                        <Text tone="critical" as="span" variant="bodyMd">
                          abort
                        </Text>{" "}
                        {t("IN_THE_BOX_BELOW")}
                      </Text>
                    }
                  />
                </BlockStack>
              </BlockStack>
            </Box>
          }
          open={abortModal !== undefined}
        />
      )}
    </>
  );
};

export default DI(RunningActivity);
