import React, { useEffect, useMemo, useRef, useState } from "react";
import ModalAppBridge from "Src/Component/HelperComponents/ModalAppBridge";
import { Box, Card, IndexTable, InlineStack, Text } from "@shopify/polaris";
import { useBulkEditContext } from "Src/Component/BulkEdit/BulkEditProvider";
import { DI } from "Src/core";
import { DIProps } from "Src/Interface/@core";
import { apiUrls } from "Src/Constant/url";
import { Empty_Line } from "Src/Component/BulkEdit/AllBulkRows";
import BulkSkeletonRowMarkup from "Src/Component/BulkEdit/Skeleton/BulkSkeletonRowMarkup";
import WeightData from "Src/Component/BulkEdit/Components/WeightData";
import DimensionsData from "Src/Component/BulkEdit/Components/DimensionsData";
import ImageAndTitle from "Src/Component/BulkEdit/Components/ImageAndTitle";
import "./../../../../BulkEdit/bulkedit.css";
import { scrollToSection } from "Src/Component/HelperComponents/Scroll";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";

interface PropsI extends DIProps {
  isOpen: boolean;
  toggleSeeAllProductsModal: () => void;
  ruleGroupPayload: any;
}
const { bulkEditproducts, bulkEditPaginations, saveBulkProducts } = apiUrls;

const SetDimensionsModal = ({
  isOpen,
  toggleSeeAllProductsModal,
  ruleGroupPayload,
  request: { POST },
  t,
}: PropsI) => {
  const { setLoading, setbulkProduct, setWeight_unit, bulkproduct, loading } =
    useBulkEditContext();
  const [saveLoading, setSaveLoading] = useState(false);
  const [nextProduct, setNextProduct] = useState({
    currentOffset: 0,
    total: 1,
    chunked_product_ids: [],
  });
  const [moreLoad, setMoreLoad] = useState(false);
  const elementref = useRef(null);
  const getOffsetspagintions = (
    bulkData: any,
    offset: number,
    chunked_product_ids: any
  ) => {
    setMoreLoad(true);
    POST(bulkEditPaginations, {
      type: "next",
      offset: offset,
      chunked_product_ids: chunked_product_ids,
    })
      .then((e) => {
        const bulkdata = { ...bulkData, ...e.bulkData };
        setbulkProduct(bulkdata);
        setNextProduct((prev) => ({
          ...prev,
          currentOffset: e.nxtoffset,
        }));
      })
      .finally(() => setMoreLoad(false));
  };

  useEffect(() => {
    const observer = new IntersectionObserver((e: any) => {
      if (
        nextProduct.total > nextProduct.currentOffset &&
        e.length === 1 &&
        e[0].isIntersecting === true
      ) {
        getOffsetspagintions(
          bulkproduct,
          nextProduct.currentOffset,
          nextProduct.chunked_product_ids
        );
      }
    });
    if (observer && elementref.current) {
      observer.observe(elementref.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [bulkproduct]);

  const getbulkProducts = () => {
    let payload = { profile_condition: ruleGroupPayload };

    POST(bulkEditproducts, payload)
      .then((e) => {
        if (e.success) {
          if (e.chunked_product_ids.length > 1) {
            setNextProduct((prev) => ({
              ...prev,
              currentOffset: 1,
              total: e.chunked_product_ids.length,
              chunked_product_ids: e.chunked_product_ids,
            }));
          }
          setbulkProduct(e.bulkData);
          setWeight_unit(
            Object.keys(e.units).map((key) => ({
              label: key,
              value: e.units[key],
            }))
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { updatedData, error } = useBulkEditContext();
  const handleSave = () => {
    if (Object.keys(error).length > 0) {
      scrollToSection(Object.keys(error[Object.keys(error)[0]])[0], 50);
      return;
    }
    setSaveLoading(true);
    POST(saveBulkProducts, {
      product: updatedData,
      selectedFields: Object.keys(updatedData).length.toString(),
    })
      .then((e) => {
        if (e.success) {
          toggleSeeAllProductsModal();
        }
        ToastAppBridge(e.message, { isError: !e.success });
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getbulkProducts();
  }, []);

  const getHeader: any = () => {
    return [
      { title: t("PRODUCT") },
      { title: t("DIMENSIONS") },
      { title: t("WEIGHT") },
    ];
  };

  const rowMarkup = useMemo(
    () =>
      Object.values(bulkproduct).map(
        (
          { product_id, images, title, variants, dimensions }: any,
          index: number
        ) => (
          <React.Fragment key={index + product_id}>
            <IndexTable.Row id={product_id} position={index}>
              <ImageAndTitle images={images} title={title} id={product_id} />
              <DimensionsData dimensions={dimensions} id={product_id} />
            </IndexTable.Row>

            {Object.values(variants).map(
              (
                { variant_title, variant_id, sku, weight, weight_unit }: any,
                index: any
              ) => (
                <IndexTable.Row
                  id={product_id}
                  key={index + variant_id + product_id}
                  position={index}
                >
                  <IndexTable.Cell
                    flush
                    className="bg-color-subdued  variant-cell"
                  >
                    <InlineStack wrap={false}>
                      <div className="variant-title-cell">
                        <Text
                          variant="bodyMd"
                          as="span"
                          fontWeight="medium"
                          truncate
                        >
                          {variant_title}
                        </Text>
                      </div>
                      <div className="variant-sku-cell">
                        <Text
                          variant="bodyMd"
                          as="span"
                          fontWeight="medium"
                          truncate
                        >
                          {t("SKU")}: {sku}
                        </Text>
                      </div>
                    </InlineStack>
                  </IndexTable.Cell>
                  <IndexTable.Cell flush className=" bg-color-subdued">
                    {Empty_Line}
                  </IndexTable.Cell>
                  <IndexTable.Cell flush className=" bg-color-subdued">
                    <WeightData
                      weight={weight}
                      unit={weight_unit}
                      id={product_id}
                      variant_id={variant_id}
                    />
                  </IndexTable.Cell>
                </IndexTable.Row>
              )
            )}
          </React.Fragment>
        )
      ),
    [bulkproduct]
  );
  return (
    <ModalAppBridge
      body={
        <Box padding="400">
          <div className="bulk-edit">
            <Card padding="0" roundedAbove="xs">
              <IndexTable
                itemCount={10}
                headings={getHeader()}
                selectable={false}
              >
                {loading ? <BulkSkeletonRowMarkup /> : rowMarkup}
                {moreLoad && <BulkSkeletonRowMarkup />}
                {!loading && <div ref={elementref}></div>}
              </IndexTable>
            </Card>
          </div>
        </Box>
      }
      id=""
      variant="large"
      open={isOpen}
      title={t("SET_PRODUCT_VALUES_INDIVIDUALLY")}
      footer={
        <>
          <button onClick={handleSave} loading={saveLoading && "true"}>
            {t("SAVE")}
          </button>
          <button variant="primary" onClick={toggleSeeAllProductsModal}>
            {t("CANCEL")}
          </button>
        </>
      }
      onHide={toggleSeeAllProductsModal}
    />
  );
};

export default DI(SetDimensionsModal);
