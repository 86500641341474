import { Box, Card, SkeletonBodyText } from '@shopify/polaris'
import React from 'react'

const TabSkeleton = () => {
    return (
        <Box>
            <Card padding={"400"}>
                <ul className="tab-buttons">
                    {Array.from({ length: 6 }, (index:any) =>
                        <li key={index}>
                            <div style={{ "width": "168px" }} className='skeleton-height-32px'>
                                <SkeletonBodyText lines={1} />
                            </div>
                        </li>
                    )}
                </ul>
            </Card>
        </Box>
    )
}

export default TabSkeleton
