import { ActionList, Button, IndexTable, Popover } from "@shopify/polaris";
import React, { useState } from "react";
import { DI } from "Src/core";
import { useBulkEditContext } from "../BulkEditProvider";

const ShopSection = ({
  id,
  etsy_attribute,
}: {
  etsy_attribute: string;
  id: string;
}) => {
  const { shop_sections, updatedData, setUpdatedData } = useBulkEditContext();
  const [popoverActive, setPopoverActive] = useState(-1);
  const shopSectionid = JSON.parse(etsy_attribute)?.shop_section_id;

  const handleChange = (e: string) => {
    const tempUpdatedData = structuredClone(updatedData);
    if (!tempUpdatedData[id]) {
      tempUpdatedData[id] = {};
    }
    tempUpdatedData[id]["shop_section_id"] = e;
    setUpdatedData(tempUpdatedData);
    setPopoverActive(-1);
  };
  const popoverOptions = (index: any) => {
    const getValue = () => {
      if (updatedData?.[id]?.["shop_section_id"]) {
        return updatedData?.[id]?.["shop_section_id"];
      } else {
        return shopSectionid;
      }
    };
    return (
      <Popover
        fullWidth
        active={popoverActive === index}
        activator={
          <div className="btn-select">
            <Button
              fullWidth
              textAlign="left"
              onClick={() => setPopoverActive(index)}
              disclosure
            >
              {shop_sections[getValue()] ?? " -- "}
            </Button>
          </div>
        }
        autofocusTarget="first-node"
        onClose={() => setPopoverActive(-1)}
      >
        <ActionList
          actionRole="menuitem"
          items={[
            ...Object.keys(shop_sections).map((e) => ({
              content: shop_sections[e],
              onAction: () => handleChange(e),
            })),
          ]}
        />
      </Popover>
    );
  };
  return (
    <IndexTable.Cell flush className="min-w-180 bg-white shop-section">
      {popoverOptions(id)}
    </IndexTable.Cell>
  );
};

export default DI(ShopSection);
