import React from "react";

const WhenYouCanClaimRefund = () => {
  return (
    <div>
        <h2>When You Can Claim The Refund?</h2>
        <p>
        Refunds only be initiated if you are not able to get a Seller Panel from Etsy.com or your account has been suspended. In this case, you can also use this money to shop for another app of Cedcommerce.
        </p>
    </div>
  )
}

export default WhenYouCanClaimRefund
