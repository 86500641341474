import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <h2>Applicability</h2>
      <p> The following Privacy Policy governs the online information collection practices of CedCommerce an entity of  Cedcoss Technologies Private Limited (“CedCommerce”, “we”, “us”, “our”). It outlines the types of information that we gather about you while you are using our website(<a target="_blank" rel="noopener" href="https://cedcommerce.com/" title="cedcommerce">https://cedcommerce.com</a>),and the ways in which we use this information.</p>
      <p>CedCommerce users who have created accounts (“User(s)”) to share their content such as information, files and folders (“Content”) to analyse their pre-existing customers, prospective customers and third parties (“Viewer(s)”) on the basis of the content viewed by them. The viewers are the non-registered users of the web-services and product offerings (“Service(s)”) provided by CedCommerce. We process your data in accordance with the applicable laws and regulations following industry best practices.</p>
    </div>
  )
}

export default PrivacyPolicy
