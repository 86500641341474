import {
  BlockStack,
  Box,
  Card,
  Checkbox,
  InlineStack,
  TabProps,
  Tabs,
  Text,
  TextField,
} from "@shopify/polaris";
import React, { useCallback, useMemo, useState } from "react";
import CustomMultiSelect from "Src/Component/Profilling/CreateProfile/components/CustomMultiSelect";
import { languagesMap } from "Src/Constant/helpData";
import { DI } from "Src/core";
import { isConfigChecked } from "Src/function";
import { DIProps } from "Src/Interface/@core";
import { useProductEditContext } from "../ProductEditProvider";
const Translation = ({ t, redux }: DIProps) => {
  const { localProduct, updateLocalProduct } = useProductEditContext();
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex: number) => setSelected(selectedTabIndex),
    []
  );

  const tabs: TabProps[] = useMemo(() => {
    return (redux?.userDetails?.etsyShop?.languages as string[])
      .slice(1)
      ?.map((lang) => {
        return {
          id: lang,
          content: languagesMap?.[lang]?.label ?? "",
          accessibilityLabel: lang,
          panelID: languagesMap?.[lang]?.name ?? "",
        };
      });
  }, [redux?.userDetails?.etsyShop?.languages]);

  const tabIdMap: { [key: number]: string } = useMemo(() => {
    return (redux?.userDetails?.etsyShop?.languages as string[])
      ?.slice(1)
      ?.reduce((prev, curr, index) => {
        return {
          ...prev,
          [index]: curr,
        };
      }, {});
  }, [redux?.userDetails?.etsyShop?.languages]);

  const selectedTab = useMemo(() => {
    return tabs?.find((tab) => tab?.id === tabIdMap[selected]);
  }, [tabs, selected, tabIdMap]);

  const isTranslationEnabled = isConfigChecked(
    localProduct?.translation?.[tabIdMap?.[selected]]?.enable
  );

  const changeTranslation = (
    key: "enable" | "title" | "description" | "tags",
    value?: string
  ) => {
    let valueToSet = value;
    if (key === "enable") {
      valueToSet = isTranslationEnabled ? "0" : "1";
    }
    const translation = JSON.parse(JSON.stringify(localProduct?.translation));
    translation[tabIdMap?.[selected]][key] = valueToSet;
    updateLocalProduct({
      translation,
    });
  };

  return (
    <BlockStack gap={"200"}>
      <Box
        paddingInlineEnd={{ xs: "400", xl: "0", md: "0", lg: "0", sm: "0" }}
        paddingInlineStart={{ xs: "400", xl: "0", md: "0", lg: "0", sm: "0" }}
      >
        <Text as="h2" variant="headingLg">
          {t("TRANSLATION")}
        </Text>
      </Box>
      <Card>
        <BlockStack gap="300">
          <Text as="p" variant="bodyMd">
            {t("TRANSLATION_DESC")}
          </Text>
          <div className="translation-tab">
            <Tabs
              tabs={tabs}
              selected={selected}
              onSelect={handleTabChange}
            ></Tabs>
          </div>
          <BlockStack gap="150">
            <InlineStack gap="300" wrap={false}>
              <div className="inte-custom__switcher">
                <Checkbox
                  label
                  checked={isTranslationEnabled}
                  onChange={() => changeTranslation("enable")}
                />
              </div>
              <Text as="p" variant="bodyLg">
                {t("TRANSLATION_TOGGLE_DESC")}
              </Text>
            </InlineStack>
            {isTranslationEnabled ? (
              <BlockStack gap="150">
                <TextField
                  label={`${selectedTab?.content} ${t("TITLE")}`}
                  placeholder={t("ENTER_TITLE")}
                  onChange={(val) => changeTranslation("title", val)}
                  autoComplete="off"
                  value={
                    localProduct?.translation?.[tabIdMap?.[selected]]?.title
                  }
                />
                <TextField
                  type="text"
                  label={`${selectedTab?.content} ${t("DESCRIPTION")}`}
                  onChange={(val) => changeTranslation("description", val)}
                  placeholder={t("ENTER_DESCRIPTION")}
                  autoComplete=""
                  multiline={4}
                  value={
                    localProduct?.translation?.[tabIdMap?.[selected]]
                      ?.description
                  }
                />
                <CustomMultiSelect
                  label={`${selectedTab?.content} ${t("TAGS")} (${t(
                    "OPTIONAL"
                  )})`}
                  value={
                    localProduct?.translation?.[tabIdMap?.[selected]]?.tags
                      ? localProduct?.translation?.[
                          tabIdMap?.[selected]
                        ]?.tags?.split(",")
                      : []
                  }
                  placeholder={t("ENTER_TAGS")}
                  onChange={(tags) =>
                    changeTranslation("tags", tags?.join(","))
                  }
                  options={[]}
                  validationObject={{
                    maxLength: 13,
                    regex: /[^a-zA-Z0-9 \-’™©®]/g,
                    charLength: 20,
                  }}
                />
              </BlockStack>
            ) : null}
          </BlockStack>
        </BlockStack>
      </Card>
    </BlockStack>
  );
};

export default DI(Translation);
