import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import "./PublicApp.css";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Navbar from "./components/Navbar";
import Pricing from "./pages/Pricing";
import Footer from "./components/Footer";
import React, { useEffect } from "react";
import { usePublicAppContext } from "./contexts/AppProvider";

function PublicApp() {
  const { setPlansState } = usePublicAppContext();

  const getPartialObjectFromKeys = (obj: any, keys: string[]) => {
    const mappedObj: any = {};
    keys.forEach((key) => {
      Object.assign(mappedObj, { [key]: obj?.[key] });
    });
    return mappedObj;
  };

  const extractPlanData = (plansData: any) => {
    const plans: any[] = [];
    const monthlyOrderCountMap = {};
    const yearlyOrderCountMap = {};
    let yearlyDiscount = 0;
    Object.entries(plansData).forEach(([id, planItem]: any) => {
      if (planItem.number_of_month === 12) {
        yearlyDiscount = Math.max(yearlyDiscount, planItem.discount_percent);
      }

      if (planItem?.name?.toLowerCase() === "lite") {
        const planObj = getPartialObjectFromKeys(planItem, [
          "name",
          "price",
          "features",
        ]);
        Object.assign(planObj, {
          id: planItem?.name_handle,
          planId: id,
          billed: planItem.number_of_month === 12 ? "yearly" : "monthly",
        });
        plans.push(planObj);
      } else {
        if (planItem.number_of_month === 12) {
          Object.assign(yearlyOrderCountMap, {
            [planItem?.order_count?.toString()]: {
              productCount: planItem?.product_count,
              price: planItem?.price,
              planId: id,
              name: planItem?.name,
              features: planItem?.features,
            },
          });
        } else {
          Object.assign(monthlyOrderCountMap, {
            [planItem?.order_count?.toString()]: {
              productCount: planItem?.product_count,
              price: planItem?.price,
              planId: id,
              name: planItem?.name,
              features: planItem?.features,
            },
          });
        }
      }
    });
    if (Object.keys(monthlyOrderCountMap).length > 0) {
      plans.push({
        id: "dynamic-m",
        planIdValues: Object.values(monthlyOrderCountMap).map(
          (val: any) => val.planId
        ),
        name: "Dynamic",
        billed: "monthly",
        orderCounts: Object.keys(monthlyOrderCountMap),
        productCounts: Object.values(monthlyOrderCountMap).map(
          (val: any) => val.productCount
        ),
        priceValues: Object.values(monthlyOrderCountMap).map(
          (val: any) => val.price
        ),
        nameValues: Object.values(monthlyOrderCountMap).map(
          (val: any) => val.name
        ),
        featuresValues: Object.values(monthlyOrderCountMap).map(
          (val: any) => val.features
        ),
      });
    }
    if (Object.keys(yearlyOrderCountMap).length > 0) {
      plans.push({
        id: "dynamic-y",
        planIdValues: Object.values(yearlyOrderCountMap).map(
          (val: any) => val.planId
        ),
        name: "Dynamic",
        billed: "yearly",
        orderCounts: Object.keys(yearlyOrderCountMap),
        productCounts: Object.values(yearlyOrderCountMap).map(
          (val: any) => val.productCount
        ),
        priceValues: Object.values(yearlyOrderCountMap).map(
          (val: any) => val.price
        ),
        nameValues: Object.values(yearlyOrderCountMap).map(
          (val: any) => val.name
        ),
        featuresValues: Object.values(yearlyOrderCountMap).map(
          (val: any) => val.features
        ),
      });
    }
    setPlansState((prev) => ({
      ...prev,
      plans,
      yearlyDiscount,
    }));
  };

  const fetchPlans = async () => {
    setPlansState((prev) => ({
      ...prev,
      loading: true,
    }));
    fetch("https://apps.cedcommerce.com/connector/etsy/api/payment/get-plans")
      .then((res) => res.json())
      .then((res) => {
        if (res?.success) {
          extractPlanData(res?.data);
        }
      })
      .finally(() => {
        setPlansState((prev) => ({
          ...prev,
          loading: false,
        }));
      });
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  return (
    <div className="ced-landing-page">
      <Navbar />
      <main className="ced-main-section">
        <Routes>
          <Route index element={<Pricing />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Navigate to="/public" />} />
        </Routes>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default PublicApp;
