import React from "react";

const YourRights = () => {
  return (
    <div>
        <h2>Your rights under the gdpr</h2>
        <p>You have certain rights if you are within the EU this includes:</p>

        <ul>
            <li>
                <h3>Right to access</h3>
                <p>
                This right allows you to obtain a copy of your personal data, as well as other supplementary information.
                </p>
            </li>
            <li>
                <h3>Right to restrict processing
                </h3>
                <p>
                You have the right to restrict the processing of your personal data in certain circumstances.
                </p>
            </li>
            <li>
                <h3>Right to rectification:
                </h3>
                <p>
                You have the right to have any incomplete or inaccurate information we hold about you corrected.
                </p>
            </li>
            <li>
                <h3>Right to object to processing.
                </h3>
                <p>
                The right to object allows you to stop or prevent us from processing your personal data. This right exists where we are relying on a legitimate interest as the legal basis for processing your Personal Data. You also have the right to object where we are processing your Personal data for direct marketing purposes.
                </p>
            </li>
            <li>
                <h3>Right to erasure:
                </h3>
                <p>
                You have the right to ask us to delete or remove Personal data when the personal data is no longer necessary for the purpose which you originally collected or processed.
                </p>
            </li>
        </ul>
        <p>To exercise your rights, you can contact us at <a className="link_color text_link" target="_blank" rel="noopener" href="mailto:cedetsyintegration@cedcommerce.com" title="cedetsyintegration@cedcommerce.com">cedetsyintegration@cedcommerce.com</a></p>
    </div>
  )
}

export default YourRights
