
import React from 'react';
const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className='ced-footer-section '>
             <div className="ced-container">
            <p className="ced-copyright-content">Copyright © {currentYear} CEDCOMMERCE | All Rights Reserved.
            </p>
            <p className="ced-trademark-content">*The term 'Etsy' is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.</p>
            </div>
        </footer>
    )
}

export default Footer
