import React, { useEffect, useState } from "react";
import { Autocomplete, BlockStack, Icon, Text } from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";
import {
  AttributesObjI,
  DIProps,
  SelectOptionI,
} from "../../../../Interface/@core";
import { DI } from "../../../../core/DependencyInjection";
import { apiUrls } from "Src/Constant/url";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import useDebounce from "Src/hooks/useDebounce";
import { useProfileContext } from "../ProfileProvider";
import { useRoutesContext } from "Src/Component/RoutesProvider";
import { modifyAttributes } from "Src/function";

const EtsyCategoryName = ({
  t,
  request: { GET },
  redux,
  dispatch,
}: DIProps) => {
  const { searchCategory, getTaxonomyAttributes } = apiUrls;
  const {
    updateBasicMapping,
    saveAttributesData,
    basicMapping: { categoryId, categoryTree },
    error,
    removeError,
  } = useProfileContext();
  const { routeData } = useRoutesContext();
  const [categoryState, setCategoryState] = useState<{
    search: string;
    loading: boolean;
    categories: any[];
  }>({
    search: "",
    loading: false,
    categories: [],
  });

  const [categoryOptions, setCategoryOptions] = useState<SelectOptionI[]>([]);
  const { changeDebouncedValue, debouncedValue } = useDebounce((val) => {
    setCategoryState((prev) => ({ ...prev, search: val }));
  }, 700);

  const getLabelMarkup = (pathArr?: string) => {
    const categoryPath =
      pathArr?.split(",") ??
      Object.values(categoryTree).reduce((prev: string[], curr: any) => {
        const parsed = JSON.parse(curr);
        const pathVal = Object.values(parsed)?.[0] as string;
        return [...prev, pathVal];
      }, []);
    if (categoryPath.length > 0) {
      return (
        <BlockStack>
          <Text as="p" variant="bodyMd" fontWeight="bold">
            <Text as="span" variant="bodyMd">
              {categoryPath[categoryPath.length - 1]} in{" "}
            </Text>
            {categoryPath.reverse()[1]}
          </Text>
          <Text as="p" variant="bodyMd" tone="subdued">
            {categoryPath.join("→")}
          </Text>
        </BlockStack>
      );
    }
    return <></>;
  };
  // handles fetching of categories data on based of search
  const fetchCategories = () => {
    if (!categoryState.search) {
      setCategoryOptions([]);
      return;
    }
    setCategoryState((prev) => ({ ...prev, loading: true }));
    GET(searchCategory, { text: categoryState.search })
      .then((res) => {
        if (res?.success) {
          setCategoryOptions(
            res?.data?.map((item: any) => ({
              label: getLabelMarkup(item?.path),
              value: item?.taxonomy_id,
            }))
          );
          setCategoryState((prev) => ({ ...prev, categories: res?.data }));
        } else {
          ToastAppBridge(res?.message, { isError: true });
        }
      })
      .finally(() => {
        setCategoryState((prev) => ({ ...prev, loading: false }));
      });
  };
  /**
   * handles updation of category selected
   * @param val updated value
   */
  const changeHandlerCategory = (val: string) => {
    const selectedIndex = categoryOptions.findIndex(
      (option) => option.value === val
    );
    changeDebouncedValue("", false);
    updateBasicMapping("categoryId", val ?? "");
    updateBasicMapping("categoryTree", getCategoryTree(selectedIndex));
  };

  const getCategoryTree = (index: number) => {
    const categoryDetails = categoryState.categories?.[index];
    const pathIds: string[] =
      categoryDetails?.full_path_taxonomy_ids?.split(",");
    const paths = categoryDetails?.path?.split(",");
    const obj = {};
    pathIds.forEach((pathId, ind) => {
      Object.assign(obj, {
        [`level-${ind + 1}`]: JSON.stringify({ [pathId]: paths[ind] }),
      });
    });
    return obj;
  };
  // fetching and saving all attributes data
  const fetchCategoryAttributes = () => {
    GET(getTaxonomyAttributes, { taxonomy_id: categoryId }).then((res) => {
      if (res?.success) {
        const simpleAttributes: AttributesObjI[] = Object.values(
          res?.response?.etsy_attributes?.optional?.simple ?? []
        );
        const variationAttributes: AttributesObjI[] = Object.values(
          res?.response?.etsy_attributes?.optional?.variations ?? []
        );
        const requiredAttributes: AttributesObjI[] = Object.values(
          res?.response?.etsy_attributes?.required ?? []
        );
        saveAttributesData({
          requiredAttributes: modifyAttributes(requiredAttributes),
          variationAttributes: modifyAttributes(variationAttributes),
          shopifyAttributes: res?.response?.shopifyAttribute,
          simpleAttributes: modifyAttributes(simpleAttributes),
        });
      } else {
        ToastAppBridge(res?.message, { isError: true });
      }
    });
  };

  useEffect(() => {
    fetchCategories();
  }, [categoryState.search]);

  useEffect(() => {
    categoryId && fetchCategoryAttributes();
  }, [categoryId]);

  return (
    <BlockStack gap={"300"}>
      <BlockStack gap={"100"}>
        <div className="custom_required">
          <Text as="h6" variant="bodyMd">
            {t("ETSY_CATEGORY")}
          </Text>
        </div>
        <Text as="p" variant="bodyMd" tone="subdued">
          {t("ETSY_CATEGORY_DESC")}
        </Text>
      </BlockStack>
      <Autocomplete
        options={categoryOptions}
        selected={[categoryId]}
        onSelect={(val) => {
          changeHandlerCategory(val?.[0]);
        }}
        emptyState={
          <React.Fragment>
            <Icon source={SearchIcon} />
            <div style={{ textAlign: "center" }}>
              <Text as="p">{t("COULD_NOT_FIND_RESULTS")}</Text>
            </div>
          </React.Fragment>
        }
        loading={false}
        textField={
          <Autocomplete.TextField
            disabled={routeData?.data?.type === "view"}
            onChange={(val) => {
              removeError("categoryId");
              changeDebouncedValue(val);
            }}
            label=""
            value={debouncedValue}
            prefix={<Icon source={SearchIcon} tone="base" />}
            placeholder={t("SEARCH_PRODUCT_CATEGORY")}
            autoComplete="off"
            loading={categoryState.loading}
            clearButton
            onClearButtonClick={() => {
              changeDebouncedValue("");
            }}
            error={error?.["categoryId"]}
          />
        }
      />
      {getLabelMarkup()}
    </BlockStack>
  );
};

export default DI(EtsyCategoryName);
