import React, { useEffect, useRef, useState } from "react";
import { NotificationIcon } from "@shopify/polaris-icons";
import {
  BlockStack,
  Box,
  Button,
  Divider,
  Icon,
  InlineStack,
  Popover,
  Text,
  useBreakpoints,
} from "@shopify/polaris";
import "./notification.css";
import NotificationsTab from "../Activities/components/NotificationsTab";
import { DI } from "Src/core";
import { DIProps, ObjStrI } from "Src/Interface/@core";
import { GetNotificationAnnouncementData } from "../Activities/Helper";
import { useShopify } from "Src/ShopifyAppBridgeProvider";
import {
  toggleNewNotification,
  getActivitySlice,
} from "Src/Redux/Slices/activity";
import { apiUrls } from "Src/Constant/url";
import { convertActivitiesResponse } from "Src/function";

interface PropsI extends DIProps {
  noVisibility?: boolean;
}

const Activator = ({
  request: { GET },
  dispatch,
  redux,
  t,
  AppBridgeRouting,
  location,
  noVisibility = false
}: PropsI) => {
  const { smUp } = useBreakpoints();
  const [selectedTab, setSelectedTab] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const notificationCount = 0;

  const reduxRef = useRef(redux);
  const isActiveRef = useRef(false);

  useEffect(() => {
    reduxRef.current = redux;
  }, [redux]);

  useEffect(() => {
    isActiveRef.current = isActive;
  }, [isActive]);

  const app = useShopify();
  useEffect(() => {
    GetNotificationAnnouncementData(GET, dispatch, redux, true);
    getWebSocketTokenAction();
  }, []);

  const getWebSocketTokenAction = async () => {
    let token: any;
    await GET(apiUrls.getWebsocketToken).then((e) => {
      token = e.data;
    });
    if ("WebSocket" in window) {
      var ws = new WebSocket(
        "wss://a5zls8ce93.execute-api.us-east-2.amazonaws.com/beta"
      );
      var send_activity =
        '{ "action": "identity","client_id":3,"customer_id":1,"token":"' +
        token +
        '"}';
      ws.onopen = function () {
        ws.send(send_activity);
        console.log("WebSocket is Active ");
      };
      ws.onmessage = function (evt) {
        var received_msg = evt.data;
        var socket_response = JSON.parse(received_msg);

        if (socket_response.feed || socket_response.notification) {
          const convertedResponse = convertActivitiesResponse({
            success: true,
            ongoing_activity: socket_response.feed,
            completed_activity: socket_response.notification,
          });
          dispatch(
            getActivitySlice({
              data: convertedResponse,
            })
          );
          const prevIds =
            reduxRef.current.activity.activityData?.completed_activity?.map(
              (ac: any) => ac?.feed_id
            );
          const newActivityAvailable = socket_response?.notification?.some?.(
            (activity: ObjStrI) => !prevIds.includes(activity?.feed_id)
          );
          const prevOngoingIds =
            reduxRef.current.activity.activityData?.ongoing_activity?.map(
              (ac: any) => ac?.id
            );
          const newOngoingActivityAvailable = socket_response.feed?.some?.(
            (activity: ObjStrI) => !prevOngoingIds.includes(activity?.id)
          );
          if (
            !reduxRef?.current?.activity?.newNotificationAvailable &&
            !isActiveRef.current &&
            (newActivityAvailable || newOngoingActivityAvailable)
            && location?.pathname?.endsWith("activity") === false
          ) {
            dispatch(toggleNewNotification(true));
          }
        }
      };
      ws.onclose = function () {
        console.log("Connection is closed...");
      };
    } else {
      console.log("WebSocket NOT supported by your Browser!");
    }
  };

  return noVisibility ? <></> :(
    <div className="app_notification_action">
      <Popover
        active={isActive}
        activator={
          <div className="notification-btn">
            <Button
              onClick={() => {
                setIsActive((prev) => !prev);
                redux.activity.newNotificationAvailable &&
                  dispatch(toggleNewNotification(false));
              }}
              icon={<Icon source={NotificationIcon} tone="base" />}
              // children={"4"}
            />
            {redux.activity.newNotificationAvailable && (
              <div
                className={`notification-count ${
                  notificationCount > 0 ? "with-count" : "without-count"
                }`}
              >
                {notificationCount > 0 && notificationCount}
              </div>
            )}
          </div>
        }
        autofocusTarget="first-node"
        onClose={() => {
          setIsActive(false);
        }}
        sectioned
        preferredAlignment="right"
      >
        <Box
          paddingBlock="200"
          paddingInline="200"
          minWidth={smUp ? "350px" : "300px"}
          maxWidth={smUp ? "350px" : "300px"}
        >
          <BlockStack gap="400">
            <InlineStack align="space-between" blockAlign="center">
              <Text variant="headingLg" as="h2">
                {t("NOTIFICATIONS")}
              </Text>
            </InlineStack>
            <BlockStack gap="300">
              <div className="activity_popover_content_scroll">
                <NotificationsTab
                  popover={true}
                  AllActivites={redux.activity.activityData}
                  AllAnnouncement={redux.activity.announcementData}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
                {selectedTab !== 0 && <Divider />}
                {selectedTab !== 0 && (
                  <InlineStack align="end">
                    <Button
                      variant="plain"
                      onClick={() => AppBridgeRouting(app, "/panel/activity")}
                    >
                      {t("VIEW_ALL")}
                    </Button>
                  </InlineStack>
                )}
              </div>
            </BlockStack>
          </BlockStack>
        </Box>
      </Popover>
    </div>
  );
};

export default DI(Activator);
