import React from "react";

const CommunicationPolicy = () => {
  return (
    <div>
        <h2>
        Communication Policy
        </h2>
         <ol>
            <li>
                <h3>
                How do we prefer communication with you?
                </h3>
                <p>
                We respect your thoughts and of course, we will always agree with your decision. In case you are not finding it useful for you, first you have to communicate properly with us so that we can understand your problem. We assure you we will never let you go with dissatisfaction.
                </p>
                <p>
                We have provided 5 mediums through which you can easily connect with us Skype, Freshchat, Mail, and Call. We will resolve every issue within 3-5 business days with the help of proper communication and details provided by your side. A healthy negotiation is the key to fruitful business and of course, you will find the same.
                </p>
            </li>

            <li>
                <h3>
                When will communicate with you?
                </h3>
                <p>
                We guarantee there will not be any delay or lack of perfection from our end but as we also depend upon Marketplace, so we can't guarantee about the same.
                </p>
                <p>
                We understand the value of your time and money and expect the same from you. We expect that if you are providing any contact details at the time of registration, it will be used by us to communicate with you in the future. We can use your contact details for the following purpose</p>
                <ol>
                    <li>To inform you about the latest updates about apps and marketplaces</li>
                    <li>To inform you about any issue with the app or marketplace</li>
                    <li>Inform you about your trial and subscription</li>
                    <li>Introducing any new product</li>
                    <li>Strategies to boost your business</li>
                </ol>
            </li>
         </ol>
         <p>We hope you will also keep in touch and cooperate with us.</p>
    </div>
  )
}

export default CommunicationPolicy
