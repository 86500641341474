export const apiUrls = {
  getSteps: "onboarding/get-steps",
  apiConnect: "onboarding/apiconnect",
  getplans: "payment/get-plans",
  subscribeplan: "payment/subscribe-plan",
  confirmPayment: "payment/confirm-payment",
  confirmAddOnPayment: "payment/confirm-order-addon-payment",
  saveConfig: "setting/save-config",
  launchChecklist: "dashboard/launch-checklist",
  topSellingProducts: "dashboard/get-top-selling-product",
  getProducts: "dashboard/get-products",
  getOrders: "dashboard/get-orders",
  getDashboardGraph: "dashboard/get-dashboard-graph",
  getAccountInfo: "dashboard/get-account-info",
  getFeedback: "dashboard/get-feedback",
  storeFeedback: "dashboard/store-feedback",
  getTemplateData: "templates/get-template-data",
  deleteTemplate: "templates/delete-templates",
  fetchShippingTemplates: "templates/fetch-shipping-templates",
  fetchShopSections: "templates/fetch-shop-section",
  fetchProductionPartners: "templates/fetch-production-partners",
  fetchReturnPolicies: "templates/fetch-policy",
  getShippingTempateById: "templates/create-shipping-template",
  createShopSection: "templates/create-shop-section",
  getReturnPolicyById: "templates/get-template-data",
  savePolicyTemplate: "templates/save-return-policy",
  getPolicyConsolidate: "templates/get-policy-consolidate",
  savePolicyConsolidate: "templates/save-policy-consolidate",
  saveTemplateData: "templates/save-template-data",
  saveShippingTemplate: "templates/save-shipping-template",
  addNewProfile: "profiling/add-new-profile",
  searchCategory: "category/search-category",
  getTemplateRuleProfile: "profiling/get-template-rules",
  getTaxonomyAttributes: "profiling/taxonomy-attributes",
  viewProducts: "profiling/view-products",
  getProfileData: "profiling/get-profile-data",
  saveProfile: "profiling/save-profiling-data",
  deleteProfile: "profiling/delete-profile",
  cloneProfile: "profiling/clone-profile",
  getProfileIdByManualQuery: "profiling/get-profile-ids-by-manual-query",
  enableDisableProfile: "profiling/enable-disable",
  getShops: "shop/get",
  viewPaymentData: "payment/get-payment-data",
  getConfig: "setting/get-setting-data",
  saveShop: "shop/save",
  getProductsData: "products/product-data",
  getVariantData: "products/variant-data",
  syncFromEtsy: "products/sync-etsy-listing",
  importCsv: "csv/import-csv",
  exportCsv: "csv/export-csv",
  bulkProcess: "products/bulk-process",
  getLocationData: "setting/get-location",
  getAllOrders: "order/order-grid-data",
  fetchOrders: "order/fetch-order",
  getProductEdit: "products/edit",
  viewOrder: "order/viewetsyorder",
  getInventoryEditGrid: "products/inventory-edit-grid",
  saveLocationWiseInventory: "products/savelocationwise-inventory",
  uploadImage: "products/upload-image-file",
  shipOrders: "order/ship-order",
  syncShippedOrders: "order/batch-order",
  emailUpdate: "order/email-update",
  getAllSKU: "order/get-all-sku",
  manualOrderCreate: "order/manual-order-create",
  getActivities: "activities/get-data",
  deleteNotification: "activities/delete-notification",
  abortProcess: "activities/abort-process",
  getAnnouncements: "activities/get-announcements",
  createProductShopify: "etsy-listing/create-product-on-shopify",
  linkingSearchProduct: "etsy-listing/search-products",
  etsyProductMapping: "etsy-listing/etsy-product-mapping",
  viewListing: "products/view-listing",
  getReviewData: "review/get-review-data",
  saveReviewData: "review/save-data",
  exportReview: "review/export-review",
  fetchReviews: "review/fetch-reviews",
  getCloseMatched: "etsy-listing/get-close-match-products",
  linkingBulkAction: "etsy-listing/bulkaction",
  getProductSyncSettings: "products/get-product-sync-settings",
  syncWithShopify: "products/sync-with-shopify",
  getNotification: "activities/get-notifications",
  viewProductUpdates: "products/view-product-updates",
  EtsyShopDetails: "dashboard/get-etsy-shop-details",
  linkImageGrid: "products/link-image-modal-grid",
  saveLinkedImages: "products/save-linked-images",
  saveProduct: "products/save-product",
  syncLocations: "setting/sync-location",
  getMapShipping: "carrier-mapping/get-map-shipping",
  saveMapShipping: "carrier-mapping/save-map-shipping",
  deleteShippingMethod: "carrier-mapping/delete-shipping-method",
  getCarrierMapping: "carrier-mapping/get",
  saveCarrierMapping: "carrier-mapping/save-mapping",
  deleteCarrierMapping: "carrier-mapping/delete-mapping",
  getWebsocketToken: "shop/get-websocket-token",
  importProduts: "productimport/start-import",
  bulkEditproducts: "products/bulk-edit",
  saveBulkProducts: "products/save-bulk-edit",
  bulkEditPaginations: "products/bulk-edit-pagination",
  getAddOnPlans: "payment/get-addon-plans",
  subscribeAddOnPlan: "payment/subscribe-addon-plans",
  startImport: "productimport/start-import",
  getImportFilterOptions: "productimport/get-filter",
  getSettingFilterOptions: "setting/get-product-filter-options",
  createWebhooks: "shop/create-webhooks",
  manualImport: "productimport/manual-import",
  getProfiledAndNotCount: "products/get-profiled-and-unprofiled-products",
  deleteActivity: "activities/delete-completed-activity",
  switchToOldUI: "shop/update-shop-extra-info",
  shopifyShopDetail: "dashboard/get-shopify-shop-details",
  digitalUploadFile: "products/upload-file",
  getDigitalUploadedFile: "products/get-digital-uploads",
  deleteCache: "dashboard/delete-cache",
  getLastRefreshDate: "dashboard/last-refresh-date",
  getCoupons: "payment/get-coupons",
  validateCoupon: "payment/validate-coupon",
  createTicket: "setting/create-ticket",
  refreshEtsyShop: "dashboard/refresh-etsy-shop-details",
};

export const externalRoutes = {
  bookMeeting:
    "https://calendly.com/cedcommerceetsyintegration/query-for-cedcommerce-etsy-integration-app",
};

export const ETSY_SHOP_URL = "https://www.etsy.com/shop/";
