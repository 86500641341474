import React from "react";

const PaymentsRefunds = () => {
  return (
    <div>
      <h2>Payments & Refunds</h2>
      <ol>
        <li>
            <h3>Payment Terms</h3>
            <p>
            Cedcommerce reserves the right and would charge you $20/hour as a customization charge for the additional on-demand benefits /requests made from your end for improving or enhancing the functionality. This charge may increase from time to time.
            </p>
        </li>
        <li>
            <h3>Free Trial </h3>
            <p>
            The free trial is of 7 days and after importing 1000 products you would be eligible to list up to 10 products on Etsy.com. There is no limit for orders in the trial period. You will get continuous support through the Ced team to set up your account. After 7 days you need to subscribe to a plan for the app.
            </p>
        </li>
        <li>
            <h3>Subscriptions</h3>
            <p>
            Some CedCommerce Services are billed on a subscription basis means that you will be billed in advance on a recurring, periodic basis each period is called a billing cycle. Billing cycles are typically monthly or annually, depending on what subscription plan you were offered. Your Subscription will automatically renew at the end of each billing cycle unless you cancel auto-renewal through your online account management page, or by contacting our customer support team. While we will be sad to see you go, you may cancel auto-renewal on your Subscription at any time, in which case your Subscription will continue until the end of that billing cycle before terminating. You may cancel auto-renewal on your Subscription immediately after the Subscription starts if you do not want it to renew. We may change the subscription fee charged for the Services at any time. The change will become effective only at the end of the then-current billing cycle of your subscription. For users subscribed under Free Subscription Plan if they choose to unsubscribe before or after the subscription end without subscribing to the Paid Plans then any Product which got published on Etsy.com Marketplace through the Etsy.com Marketplace Integration App would get retired from Etsy.com and would not be available for purchase at Etsy.com Marketplace through us and functionalities of our app will be disabled for that users as soon as the Free Subscription Plan Expires.
            </p>
        </li>
        <li>
            <h3>Refunds</h3>
            <p>
            You are responsible for keeping your billing information up to date and your account current. You will not be liable for any sort of partial or prorated refund of your subscription fee for any time during which you do not use the Services. The refund would not apply to the boundaries beyond the operational field and the issues/concerns/liabilities not under the CedCommerce service policies and norms. Sales of Products on Etsy.com Marketplace entirely depend on your product, Etsy.com, and Purchasing Client. App Provides Systems and Tools to make Sale Easier but does not guarantee sales. No Refund Policy is applicable in such scenarios where the App is considered to generate revenue on Etsy.com. In case any partial or complete refund is made to any user then his account would be completely removed from our records. No support will be provided to the item that got published on Etsy.com Marketplace through our app thereafter as well as such product on Etsy.com will be recalled/retired from Etsy.com Marketplace.
            </p>
        </li>
      </ol>
    </div>
  )
}

export default PaymentsRefunds
