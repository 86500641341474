
import React from 'react';
const BannerSection = ({title}:any) => {
  return (
    <section className='ced-banner-section'>
        <div className='ced-container'>
            <h1 className="ced-page-head">{title}</h1>
            
        </div>
    </section>
  )
}

export default BannerSection
