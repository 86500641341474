import {
  BlockStack,
  Box,
  Button,
  Card,
  Icon,
  InlineStack,
  Link,
  Text,
} from "@shopify/polaris";
import { DeleteIcon } from "@shopify/polaris-icons";
import Status from "../Dashboard/components/Status";
import { NoGenralNotificationFallback } from "../Fallbacks/FallBackPages";
import { apiUrls } from "Src/Constant/url";
import { Dispatch, UnknownAction } from "redux";
import { DIProps, ReduxI } from "Src/Interface/@core";
import {
  getActivitySlice,
  getAnouncementSlice,
} from "Src/Redux/Slices/activity";
import { convertActivitiesResponse, getActivityRedirectionObj, getFormattedDateTime } from "Src/function";
import { ToastAppBridge } from "../HelperComponents/Toast";
import { useShopify } from "Src/ShopifyAppBridgeProvider";
export const GetStatusType = (severity: string) => {
  switch (severity.toLowerCase()) {
    case "success":
      return "success";
    case "critical":
    case "danger":
      return "danger";
    case "info":
      return "warning";
    default:
      return "warning";
  }
};
const { deleteActivity } = apiUrls;
export const RecentCompletedActivities = (
  _props: DIProps,
  completed_activity: any,
  limit?: number,
  popover?: boolean
) => {
  const {
    redux,
    globalState: { get },
    request: { GET, POST },
    dispatch,
    AppBridgeRouting,
  } = _props;
  const { app } = useShopify();
  const activitiesToDisplay = limit
    ? completed_activity?.slice(0, limit)
    : completed_activity;
  const clickHandlerActivityAction = (href: string) => {
    const { redirectionLink, redirectionType } =
      getActivityRedirectionObj(href);
    if (redirectionType === "external") {
      window.open(redirectionLink);
    } else {
      AppBridgeRouting(app, redirectionLink);
    }
  };
  if (activitiesToDisplay?.length > 0) {
    return activitiesToDisplay.map((recentActivity: any) => {
      const regex = /(<a .*?>.*?<\/a>)/;
      const messages = recentActivity.message.split(regex);

      return (
        <div
          className={`notification-item ${
            popover ? "popover_activity_item" : ""
          }`}
          key={recentActivity.id}
        >
          <Box padding="0" borderRadius="200">
            {/* notification item */}
            <InlineStack wrap={false} gap="200" blockAlign="start">
              <Status type={GetStatusType(recentActivity.severity)} />
              <div style={{ flex: 1 }}>
                <BlockStack gap="200">
                  <InlineStack align="space-between" wrap={false} gap="200">
                    <Text as="p" variant="bodyLg" fontWeight="semibold">
                      {recentActivity.title || "--"}
                    </Text>
                    <Button
                      variant="plain"
                      onClick={() => {
                        POST(deleteActivity, {
                          id: recentActivity.id,
                          shop_url: get("shop"),
                        }).then((res) => {
                          ToastAppBridge(res.message, {
                            isError: !res.success,
                          });
                          res.success &&
                            GetNotificationAnnouncementData(
                              GET,
                              dispatch,
                              redux,
                              true
                            );
                        });
                      }}
                      icon={<Icon source={DeleteIcon} tone="critical" />}
                    />
                  </InlineStack>
                  <BlockStack gap="100" inlineAlign="start">
                    <InlineStack gap={"200"} align="start" blockAlign="center">
                      <Text as="span" variant="bodyMd" tone="subdued">
                        {messages.map((text: string, index: number) => {
                          const anchorTagRegex =
                            /<a\s+href="[^"]*".*?>.*?<\/a>/;
                          const anchorTagRegexSingle =
                            /<a\s+href='[^']*'.*?>.*?<\/a>/;
                          const hasAnchorTag =
                            anchorTagRegex.test(text) ||
                            anchorTagRegexSingle.test(text);
                          if (hasAnchorTag) {
                            const hrefRegex = /href="([^"]*)"/;
                            const hrefRegexSingle = /href='([^']*)'/;
                            const contentRegex = /<a .*?>(.*?)<\/a>/;
                            const hrefMatch =
                              RegExp(hrefRegex).exec(text) ??
                              RegExp(hrefRegexSingle).exec(text) ??
                              [];
                            const contentMatch =
                              RegExp(contentRegex).exec(text) ?? [];
                            return (
                              <Link
                                key={index + text}
                                onClick={() => {
                                  clickHandlerActivityAction(hrefMatch[1]);
                                }}
                              >
                                {contentMatch[1]}
                              </Link>
                            );
                          }

                          return text;
                        })}
                      </Text>
                    </InlineStack>
                    {recentActivity.link && (
                      <Button textAlign="left" variant="plain">
                        {recentActivity.link}
                      </Button>
                    )}
                  </BlockStack>
                  <Text as="p" variant="bodySm" tone="subdued">
                    {getFormattedDateTime(recentActivity.created_at, true)}
                  </Text>
                </BlockStack>
              </div>
            </InlineStack>
          </Box>
        </div>
      );
    });
  }

  return (
    <Card roundedAbove="xs">
      <NoGenralNotificationFallback />
    </Card>
  );
};

const { getActivities, getNotification } = apiUrls;
export const GetNotificationAnnouncementData = (
  GET: (
    endpoint: string,
    params?: {
      [name: string]: string;
    },
    fullUrl?: boolean
  ) => Promise<any>,
  dispatch: Dispatch<UnknownAction>,
  redux: ReduxI,
  refresh: boolean
) => {
  if (
    Object.keys(redux?.activity?.activityData ?? {})?.length === 0 ||
    refresh
  ) {
    GET(getActivities).then((e) => {
      const convertedResponse = convertActivitiesResponse(e);
      dispatch(getActivitySlice({ data: convertedResponse }));
    });
  }
  if (
    Object.keys(redux?.activity?.activityData ?? {})?.length === 0 ||
    refresh
  ) {
    GET(getNotification).then((e) => {
      dispatch(getAnouncementSlice({ data: e.data }));
    });
  }
};
