import { IndexTable, TextField } from "@shopify/polaris";
import React from "react";

const VendorData = ({ vendor }: { vendor: string }) => {
  return (
    <IndexTable.Cell flush className="min-w-180 bg-white ">
      <TextField
        labelHidden
        label="Analog"
        autoComplete=""
        disabled
        value={vendor}
      />
    </IndexTable.Cell>
  );
};

export default VendorData;
