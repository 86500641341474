import { IndexTable } from "@shopify/polaris";
import React from "react";
import CustomMultiSelect from "Src/Component/Profilling/CreateProfile/components/CustomMultiSelect";
import { DI } from "Src/core";
import { useBulkEditContext } from "../BulkEditProvider";
import { DIProps } from "Src/Interface/@core";

const TagsData = ({ tags, id, t }: { tags: string; id: string } & DIProps) => {
  const { updatedData, setUpdatedData } = useBulkEditContext();
  const getValue = () => {
    if (updatedData?.[id]?.["tags"]) {
      return updatedData?.[id]?.["tags"]?.trim() !== ""
        ? updatedData?.[id]?.["tags"]?.split(",")
        : [];
    } else {
      return tags?.trim() !== "" ? tags?.split(",") : [];
    }
  };
  return (
    <IndexTable.Cell flush className="min-w-260  bg-white ">
      <div className="combobox-tags">
        <CustomMultiSelect
          options={[]}
          value={getValue()}
          placeholder={t("ENTER_TAGS")}
          onChange={(e) => {
            const tempUpdatedData = structuredClone(updatedData);
            if (!tempUpdatedData[id]) {
              tempUpdatedData[id] = {};
            }
            tempUpdatedData[id]["tags"] = e.join(",");
            setUpdatedData(tempUpdatedData);
          }}
        />
      </div>
    </IndexTable.Cell>
  );
};

export default DI(TagsData);
