import React from "react";
import FaqsAccordion from "./FaqsAccordion";
import { DarkArrow } from "../assets/icons/Icons";

const FaqSection = () => {
  return (
    <section className="ced-faq-section ced-section-space">
      <div className="ced-container">
        <div className="ced-faq-section-header">
          <h2 className="ced-section-head">
            Frequently Asked <span>Questions</span>
          </h2>
          <button
            onClick={() =>
              window.open(
                "https://support.cedcommerce.com/portal/en/kb/etsy-integration/shopify/faq"
              )
            }
            title="View More"
            className="ced-btn ced-btn-action ced-animate-action"
          >
            View More
            <span className="ced-arrow-icon">
              <DarkArrow />
            </span>
          </button>
        </div>
        <FaqsAccordion />
      </div>
    </section>
  );
};

export default FaqSection;
