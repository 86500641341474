import React, { useEffect, useState } from "react";
import GeneralInformation from "./component/GeneralInformation";
import EtsyAttribute from "./component/EtsyAttribute";
import VariationInformation from "./component/VariationInformation";
import Gallery from "./component/Gallery";
import { BlockStack, Box, Card, Layout, Page, useBreakpoints } from "@shopify/polaris";
import ProductSyncing from "./component/ProductSyncing";
import "./productedit.css";
import { AttributesObjI, DIProps, ObjStrI } from "Src/Interface/@core";
import { DI } from "Src/core";
import { useProductEditContext } from "./ProductEditProvider";
import { apiUrls } from "Src/Constant/url";
import { useParams } from "react-router-dom";
import { ToastAppBridge } from "../HelperComponents/Toast";
import { isConfigChecked, modifyAttributes } from "Src/function";
import ProductEditSkeleton from "./Skeleton/ProductEditSkeleton";
import { getProductEditErrors } from "./function";
import { scrollToSection } from "../HelperComponents/Scroll";
import { getTrimLength } from "../CharacterLimitSet";
import Translation from "./component/Translation";

const ProductEdit = ({
  request: { GET, PUT },
  t,
  navigate,
  redux
}: DIProps) => {
  const {
    localProduct,
    localVariants,
    productLoading,
    orderedImages,
    saveLoading,
    toggleProductLoading,
    saveProductData,
    saveLocalProduct,
    saveLocalVariants,
    toggleSaveLoading,
    errors,
    saveErrors,
  } = useProductEditContext();
  const { productId } = useParams();

const {mdUp} = useBreakpoints();

  const tabs = [
    {
      _id: 0,
      label: t("GENERAL_INFORMATION"),
      key: "generalInformation",
      section: "general_info_sec",
    },
    {
      _id: 1,
      label: t("PRODUCT_SYNCING"),
      key: "productsyncing",
      section: "product_sync_sec",
    },
    {
      _id: 2,
      label: t("ETSY_ATTRIBUTE"),
      key: "etsyAttribute",
      section: "attribute_sec",
    },
    {
      _id: 3,
      label: t("GALLERY"),
      key: "gallery",
      section: "gallery_sec",
    },
    {
      _id: 4,
      label: t("VARIATION_INFORMATION"),
      key: "variationInformation",
      section: "variation_info_sec",
    },
    redux?.userDetails?.etsyShop?.languages?.length > 1
      ? {
          _id: 5,
          label: "Translation",
          key: "translation",
          section: "translation_sec",
        }
      : null,
  ];
  const tabsToShow = tabs.filter((tab) => tab !== null);

  const getProductData = () => {
    toggleProductLoading();
    GET(apiUrls.getProductEdit, { id: productId ?? "" })
      .then((res) => {
        if (res?.success) {
          const recommendedAttributes: AttributesObjI[] = Object.values(
            res?.data?.etsy_attributes?.simple ?? []
          );
          const variantAttributes: AttributesObjI[] = Object.values(
            res?.data?.etsy_variations_array ?? []
          );
          let tempOrderedImages = [];
          if (res?.data?.product?.ordered_images) {
            tempOrderedImages =
              res?.data?.product?.ordered_images?.primary ?? [];
            if (res?.data?.product?.ordered_images?.secondary) {
              Object.values(
                res?.data?.product?.ordered_images?.secondary
              ).forEach((val: any) => {
                tempOrderedImages.push({ url: val?.url, alt: val?.alt });
              });
            }
          } else {
            tempOrderedImages = res?.data?.product?.images ?? [];
          }
          let dimensions = res?.data?.product?.dimensions
            ? { ...res?.data?.product?.dimensions }
            : {};
          if (
            (!dimensions || Object.keys(dimensions).length === 0) &&
            res?.data?.profile_mapped?.default_dimensions
          ) {

            dimensions = { ...res?.data?.profile_mapped?.default_dimensions };
          }
          let weightDimensions = {
            weight:
              res?.data?.productCommonField?.weight ??
              res?.data?.profile_mapped?.default_dimensions?.weight,
            weight_unit:
              res?.data?.productCommonField?.weight_unit ??
              res?.data?.profile_mapped?.default_dimensions?.weight_unit,
          };
          Object.assign(dimensions, { ...weightDimensions });
          const shipping_data = {
            ...res?.data?.product?.shipping_data,
          };
          const profileShippingKeys = [
            "processing_min",
            "processing_max",
            "processing_unit",
          ];
          profileShippingKeys.forEach((key) => {
            if (!shipping_data?.[key]) {
              let keyToPick =
                key === "processing_unit" ? "processing_time_unit" : key;
              let value = res?.data?.productCommonField?.[keyToPick];
              if (key === "processing_unit") {
                value = value.replace("business_", "");
              }
              shipping_data[key] = value;
            }
          });
          const should_auto_renew =
            res?.data?.product?.should_auto_renew ??
            res?.data.profile_mapped.requiredAttributes.should_auto_renew;
          let metafieldsValues = res?.data?.metafields ?? [];
          if (!Array.isArray(metafieldsValues)) {
            metafieldsValues = Object.keys(metafieldsValues);
          }
          let translation = {};
          if (
            res?.data?.product?.translation &&
            Object.keys(res?.data?.product?.translation).length > 0
          ) {
            translation = { ...res?.data?.product?.translation };
          } else {
            translation = (redux?.userDetails?.etsyShop?.languages as string[])
              .filter((lang) => lang?.trim() !== "en-US")
              ?.reduce((prev, lang: string) => {
                return {
                  ...prev,
                  [lang]: {
                    enable: "0",
                    title: "",
                    description: "",
                    tags: "",
                  },
                };
              }, {});
          }
          saveProductData({
            product: {
              ...res?.data?.product,
              dimensions,
              shipping_data,
              should_auto_renew,
            },
            recommendedAttributes: modifyAttributes(recommendedAttributes),
            multilocationEnabled: isConfigChecked(
              res?.data?.multilocationEnabled
            ),
            variants: res?.data?.variants ?? [],
            variantAttributes: modifyAttributes(variantAttributes),
            shopifyAttributeNames: res?.data?.productOptionName,
            orderedImages: tempOrderedImages,
            templateOptionsMap: {
              policies: res?.data?.productRequiredField?.[1]?.policy ?? {},
              shipping:
                res?.data?.productRequiredField?.[1]?.shipping_template_id ??
                {},
              shopSections:
                res?.data?.productCommonField?.shop_section_id ?? {},
            },
            profileMapped: res?.data?.profile_mapped,
            metafields: metafieldsValues,
            mapped_attribute_options : res?.data?.mapped_attribute_options
          });
          saveLocalProduct({
            ...res?.data?.product,
            translation,
            dimensions,
            shipping_data,
            should_auto_renew,
          });
          saveLocalVariants(res?.data?.variants ?? []);
        } else {
          ToastAppBridge(res?.message);
        }
      })
      .finally(() => {
        toggleProductLoading();
      });
  };

  const getEditPayload = () => {
    const ordered_images = {};
    if (orderedImages?.[0]) {
      Object.assign(ordered_images, {
        primary: orderedImages[0],
      });
    }
    if (orderedImages.length > 1) {
      const secondaryImages = orderedImages
        .slice(1)
        .reduce((prev, curr, index) => {
          return {
            ...prev,
            [index + 1]: curr,
          };
        }, {});
      Object.assign(ordered_images, {
        secondary: secondaryImages,
      });
    }
    const etsyProductVariants = localVariants.reduce((prev, curr) => {
      const option_value={};
      curr?.variantOptionValues?.forEach((item:ObjStrI)=>{
        Object.assign(option_value,{[item?.option_name]:
          item?.option_value})
      })
      return {
        ...prev,
        [curr?.variant_id]: {
          price: curr?.price,
          is_taxable: curr?.is_taxable,
          inventory: curr?.inventory,
          option_value: option_value,
          disable_variants: curr?.disable_variants,
        },
      };
    }, {});

    const imageLinkingObj =
      localProduct?.optionValuesArr?.reduce(
        (prev: ObjStrI, curr: string, index: number) => {
          return { ...prev, [curr]: localProduct?.linkedImageArr?.[index] };
        },
        {}
      ) ?? {};

    const imageLinkingKeys = ["optionValuesArr", "linkedImageArr"];
    imageLinkingKeys.forEach((key) => {
      Object.assign(imageLinkingObj, {
        [key]: localProduct?.[key] ? localProduct?.[key]?.join(",") : "",
      });
    });

    const payload = {
      EtsyProducts: {
        product_id: productId,
        title: localProduct?.title,
        description: localProduct?.description,
        tags: localProduct?.tags,
        image: localProduct.image?.[0].url,
        who_made: localProduct?.who_made,
        is_supply: localProduct?.is_supply,
        when_made: localProduct?.when_made,
        shipping_template_id: localProduct?.shipping_template_id,
        policy: localProduct?.policy,
        is_personalizable: localProduct?.is_personalizable,
        is_customizable: localProduct?.is_customizable,
        should_auto_renew: localProduct?.should_auto_renew,
        shop_section_id: localProduct?.shop_section_id,
        dimensions: localProduct?.dimensions,
        materials: localProduct?.materials,
        styles: localProduct?.styles,
        processing_min: localProduct?.shipping_data?.processing_min,
        processing_max: localProduct?.shipping_data?.processing_max,
        processing_unit: localProduct?.shipping_data?.processing_unit,
        ordered_images: ordered_images,
        image_linking_option: localProduct?.image_linking_option,
        variant_image_linking: localProduct?.image_linking_option,
        sync_fields: localProduct?.sync_fields,
      },
      EtsyTranslation: localProduct?.translation,
      category_management: {},
      EtsyProductVariants: etsyProductVariants,
      ...imageLinkingObj,
    };
    if (isConfigChecked(payload?.EtsyProducts?.is_personalizable)) {
      const personalizationOptions = {
        personalization_is_required: localProduct?.personalization_is_required,
        personalization_char_count_max:
          localProduct?.personalization_char_count_max,
        personalization_instructions:
          localProduct?.personalization_instructions,
      };
      Object.assign(payload?.EtsyProducts, personalizationOptions);
    }
    if (!payload?.EtsyProducts?.sync_fields) {
      Object.assign(payload?.EtsyProducts, { use_global_settings: "1" });
    }
    if (!localProduct?.recommendedAttributes) {
      Object.assign(payload?.EtsyProducts, { use_profile_settings: "1" });
    } else {
      Object.assign(payload?.category_management, {
        recommendedAttributes: localProduct?.recommendedAttributes,
      });
    }
    if (!localProduct?.option_name) {
      Object.assign(payload, { Use_profile_mapping: "1" });
    } else {
      Object.assign(payload, {
        option_name: localProduct?.option_name,
        option_name_selected: localProduct?.option_name_selected,
      });
    }
    return payload;
  };

  const saveEditProduct = () => {
    const errorsObj = getProductEditErrors(localProduct, errors);
    if (Object.keys(errorsObj).length > 0) {
      saveErrors(errorsObj);
      scrollToSection(Object.keys(errorsObj)?.[0], 50);
      return;
    }
    toggleSaveLoading();
    const payload = getEditPayload();
    PUT(apiUrls.saveProduct, payload)
      .then((res) => {
        ToastAppBridge(res?.message ?? res?.msg, { isError: !res?.success });
      })
      .finally(() => {
        toggleSaveLoading();
      });
  };

  useEffect(() => {
    getProductData();
  }, []);

  const getTitle: any = (
    <span className="custom_flex">
      <span className="thumbnail_wrapper">
        <img
          src={orderedImages?.[0]?.url}
          className="product_edit_img-thumnail"
          alt=""
        />
      </span>

      {getTrimLength(localProduct?.title ?? "", 50, "medium", "span", "bodyMd")}
    </span>
  );

  const [activeLink, setActiveLink] = useState<any>("general_info_sec");

  const handleScroll = (sectionId: any) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      const offsetTop = sectionElement.offsetTop - 100; 
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
      setActiveLink(sectionId);
    }
  };

  const handelScroll = () => {
    const headerHeight = 100; 
    const sections = document.getElementsByClassName("product_edit_Section");
    const tabLiList = document
      .getElementById("tab-content-box")
      ?.getElementsByTagName("li");
  
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const sectionTop = section.getBoundingClientRect().top - headerHeight; 
  
      if (sectionTop <= 0) {
        if (tabLiList?.length) {
          Array.from(tabLiList).forEach((li) => li.classList.remove("active")); 
          tabLiList[i]?.classList.add("active"); 
        }
      } else {
        break; 
      }
    }
  };

  useEffect(() => {
    if (productLoading) return;
    window.addEventListener("scroll", handelScroll);
    return () => window.removeEventListener("scroll", handelScroll);
  }, [productLoading]);
  return (
    <>
     <div className="header-sticky">
      <Box borderBlockEndWidth={mdUp ? "025" : "0"} borderColor="border">
        <div className="custom_layout_width">
          <div className="custom_page_header">
            <Page
              backAction={{
                content: "",
                onAction: () => {
                  navigate("/panel/listings");
                },
              }}
              fullWidth
              title={getTitle}
              primaryAction={{
                content: t("SAVE"),
                onAction: saveEditProduct,
                loading: saveLoading,
              }}
              // primaryAction={<Activator />}
              // secondaryActions={[
              //   {
              //     content: "Save",
              //     onAction: saveEditProduct,
              //     loading: saveLoading,
              //   }
              // ]}
            />
          </div>
        </div>
      </Box>
      </div>
      <div className="custom_layout_width">
        <Page fullWidth>
          <Layout>
            <Layout.Section>
              {productLoading ? (
                <ProductEditSkeleton />
              ) : (
                <div className="inte-Tab-outer settings-tab product-edit-tab">
                  <Box id="tab-content-box">
                    <Card padding={"400"}>
                      <ul className="tab-buttons">
                        {tabsToShow.map((tab, index) => (
                          <li
                            key={tab._id}
                            className={
                              activeLink === tab.section ? "active" : ""
                            }
                          >
                            <a
                              className={`Polaris-Button Polaris-Button--pressable Polaris-Button--variantTertiary Polaris-Button--sizeMedium Polaris-Button--textAlignLeft Polaris-Button--fullWidth`}
                              // href={"#" + tab.section}
                              onClick={() => {
                                handleScroll(tab.section);
                              }}
                            >
                              <span className="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
                                {tab.label}
                              </span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </Card>
                  </Box>
                  <div>
                    <BlockStack gap={"600"}>
                      <div
                        className="product_edit_Section"
                        id="general_info_sec"
                      >
                        <GeneralInformation />
                      </div>
                      <div
                        className="product_edit_Section"
                        id="product_sync_sec"
                      >
                        <ProductSyncing />
                      </div>
                      <div className="product_edit_Section" id="attribute_sec">
                        <EtsyAttribute />
                      </div>
                      <div className="product_edit_Section" id="gallery_sec">
                        <Gallery />
                      </div>
                      <div
                        className="product_edit_Section"
                        id="variation_info_sec"
                      >
                        <VariationInformation />
                      </div>
                      {redux?.userDetails?.etsyShop?.languages?.length > 1 ? (
                        <div
                          className="product_edit_Section"
                          id="translation_sec"
                        >
                          <Translation />
                        </div>
                      ) : null}
                    </BlockStack>
                  </div>
                </div>
              )}
            </Layout.Section>
          </Layout>
        </Page>
      </div>
    </>
  );
};

export default DI(ProductEdit);
