import React from "react";

const YourInformation = () => {
  return (
    <div>
        <h2>Use of Information</h2>
        <p>
        We will use the contact information that is being gathered from you for the following purposes :
        </p>
        <ul>
            <li>Order and Transaction Related Information</li>
            <li>Sending Associated Product Emails based on the interest shows</li>
            <li>Sending New Product Information</li>
            <li>Product Related and Major Updates</li>
            <li>Improving the overall structure of the site to give personalized experience based on personal traits</li>
            <li>Conduct surveys, promotions, and marketing</li>
            <li>Payment Related Telephonic Conversations</li>
            <li>Feedback-related Mails and Calls</li>
            <li>Informational Mails and Calls</li>
            <li>Industry-related news and updates</li>
            <li>Scheduled Calling</li>
            <li>Service Related Mails</li>
            <li>Notification Mails</li>
            <li>Text Messaging Service</li>
        </ul>
        <p>There is always a procedure for unsubscription to receiving such emails and newsletters and after doing so you won’t be receiving such information</p>
    </div>
  )
}

export default YourInformation
