import React from "react";

const UsageOfService = () => {
  return (
    <div>
      <h2>Usage of the Service</h2>
      <p>
      You are responsible for your account, content, and communications with others while using the Services. You agree to use the Services in compliance with applicable law. This is your responsibility and you agree to not use CedCommerce in a way that violates Etsy.com Terms of Use. By using CedCommerce.com, you agree to not violate any of the marketplace terms. In the event of violating any marketplace rules, we have the right to close your account. We are also not responsible for any illegal use of CedCommerce.com by our users.
      </p>
    </div>
  )
}

export default UsageOfService
