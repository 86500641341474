import React, { createContext, ReactNode, useContext, useState } from "react";
interface PlansStateI {
  loading: boolean;
  plans: any[];
  yearlyDiscount: number;
}
const AppContext = createContext<{
  plansState: PlansStateI;
  setPlansState: React.Dispatch<React.SetStateAction<PlansStateI>>;
}>({
  plansState: { loading: false, plans: [], yearlyDiscount: 0 },
  setPlansState: () => {},
});

const PublicAppProvider = ({ children }: { children: ReactNode }) => {
  const [plansState, setPlansState] = useState<PlansStateI>({
    loading: false,
    plans: [],
    yearlyDiscount: 0,
  });

  return (
    <AppContext.Provider value={{ plansState, setPlansState }}>
      {children}
    </AppContext.Provider>
  );
};

const usePublicAppContext = () => useContext(AppContext);
export { PublicAppProvider, usePublicAppContext };
