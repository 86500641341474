import { InlineStack, Select, TextField } from "@shopify/polaris";
import React from "react";
import { useBulkEditContext } from "../BulkEditProvider";
import { DI } from "Src/core";
import useDebounce from "Src/hooks/useDebounce";
import { regexExpressions } from "Src/Constants";

const WeightData = ({
  weight,
  unit,
  id,
  variant_id,
}: {
  weight: string;
  unit: any;
  id: string;
  variant_id: string;
}) => {
  const { updatedData, setUpdatedData, weight_unit } = useBulkEditContext();
  const getValueUnit = () => {
    if (updatedData?.[id]?.["variants"]?.[variant_id]?.["weight_unit"]) {
      return updatedData[id]["variants"][variant_id]["weight_unit"];
    } else {
      return unit;
    }
  };
  const getValue = () => {
    if (updatedData?.[id]?.["variants"]?.[variant_id]?.["weight_unit"]) {
      return updatedData[id]["variants"][variant_id]["weight_unit"];
    } else {
      return weight;
    }
  };
  const handleFiltersQueryChange = (value: string) => {
    const tempUpdatedData = structuredClone(updatedData);
    if (!tempUpdatedData[id]) {
      tempUpdatedData[id] = {};
    }

    if (!tempUpdatedData[id]["variants"]) {
      tempUpdatedData[id]["variants"] = {};
    }

    if (!tempUpdatedData[id]["variants"][variant_id]) {
      tempUpdatedData[id]["variants"][variant_id] = {};
    }

    tempUpdatedData[id]["variants"][variant_id]["weight"] = value;
    setUpdatedData(tempUpdatedData);
  };
  const { changeDebouncedValue, debouncedValue } = useDebounce(
    handleFiltersQueryChange,
    300,
    getValue()
  );
  return (
    <InlineStack wrap={false} gap="100">
      <TextField
        label="Quantity"
        labelHidden
        type="text"
        value={debouncedValue}
        onChange={(newValue: string) => {
          if (
            regexExpressions.NUMBER_CHECK_WITH_DOT.test(newValue) ||
            newValue.trim() === ""
          )
            changeDebouncedValue(newValue);
        }}
        autoComplete="off"
      />
      <div className="custom-unit-select weight-cell">
        <Select
          onChange={(e) => {
            const tempUpdatedData = structuredClone(updatedData);
            if (!tempUpdatedData[id]) {
              tempUpdatedData[id] = {};
            }

            if (!tempUpdatedData[id]["variants"]) {
              tempUpdatedData[id]["variants"] = {};
            }

            if (!tempUpdatedData[id]["variants"][variant_id]) {
              tempUpdatedData[id]["variants"][variant_id] = {};
            }

            tempUpdatedData[id]["variants"][variant_id]["weight_unit"] = e;
            setUpdatedData(tempUpdatedData);
          }}
          label="weight"
          placeholder="unit"
          value={getValueUnit()}
          labelHidden
          options={weight_unit}
        />
      </div>
    </InlineStack>
  );
};

export default DI(WeightData);
