import {
  IndexTable,
  InlineStack,
  TextField,
  Thumbnail,
} from "@shopify/polaris";
import React from "react";
import { DI } from "Src/core";
import { useBulkEditContext } from "../BulkEditProvider";
import useDebounce from "Src/hooks/useDebounce";

const ImageAndTitle = ({
  images,
  title,
  id,
}: {
  images: string;
  title: string;
  id: string;
}) => {
  const image = JSON.parse(images)?.[0];
  const { updatedData, setUpdatedData } = useBulkEditContext();
  const getValue = () => {
    if (updatedData?.[id]?.["title"]) {
      return updatedData?.[id]?.["title"];
    } else {
      return title;
    }
  };
  const handleFiltersQueryChange = (e: string) => {
    const tempUpdatedData = structuredClone(updatedData);
    if (!tempUpdatedData[id]) {
      tempUpdatedData[id] = {};
    }
    tempUpdatedData[id]["title"] = e;
    setUpdatedData(tempUpdatedData);
  };
  const { changeDebouncedValue, debouncedValue } = useDebounce(
    handleFiltersQueryChange,
    700,
    getValue()
  );

  return (
    <IndexTable.Cell flush className="min-w-320 title-cell bg-white">
      <InlineStack gap="200" blockAlign="center" wrap={false}>
        <Thumbnail
          size="extraSmall"
          source={image.url}
          alt={image.alt || title}
        />

        <TextField
          label="Title"
          labelHidden
          value={debouncedValue}
          onChange={(e) => changeDebouncedValue(e)}
          autoComplete="off"
        />
      </InlineStack>
    </IndexTable.Cell>
  );
};

export default DI(ImageAndTitle);
