import { TextField } from "@shopify/polaris";
import React, { useEffect } from "react";
import { DI } from "Src/core";
import { useBulkEditContext } from "../BulkEditProvider";
import useDebounce from "Src/hooks/useDebounce";
import { regexExpressions } from "Src/Constants";

const InventoryData = ({
  inventory,
  id,
  variant_id,
}: {
  inventory: string;
  id: string;
  variant_id: string;
}) => {
  const { updatedData, setUpdatedData } = useBulkEditContext();
  const getValue = () => {
    if (updatedData?.[id]?.["variants"]?.[variant_id]?.["inventory"]) {
      return updatedData[id]["variants"][variant_id]["inventory"];
    } else {
      return inventory;
    }
  };
  const handleFiltersQueryChange = (e: string) => {
    const tempUpdatedData = structuredClone(updatedData);
    if (!tempUpdatedData[id]) {
      tempUpdatedData[id] = {};
    }

    if (!tempUpdatedData[id]["variants"]) {
      tempUpdatedData[id]["variants"] = {};
    }

    if (!tempUpdatedData[id]["variants"][variant_id]) {
      tempUpdatedData[id]["variants"][variant_id] = {};
    }

    tempUpdatedData[id]["variants"][variant_id]["inventory"] = e;
    setUpdatedData(tempUpdatedData);
  };
  useEffect(() => {
    if (updatedData?.[id]?.["variants"]?.[variant_id]?.["inventory"]) {
      changeDebouncedValue(
        updatedData?.[id]?.["variants"]?.[variant_id]?.["inventory"],
        false
      );
    }
  }, [updatedData?.[id]?.["variants"]?.[variant_id]?.["inventory"]]);
  const { changeDebouncedValue, debouncedValue } = useDebounce(
    handleFiltersQueryChange,
    700,
    getValue()
  );
  return (
    <TextField
      label="Quantity"
      labelHidden
      type="text"
      value={debouncedValue}
      onChange={(newValue: string) => {
        if (
          regexExpressions.NUMBER_WITHOUT_DOT.test(newValue) ||
          newValue.trim() === ""
        )
          changeDebouncedValue(newValue);
      }}
      autoComplete="off"
    />
  );
};

export default DI(InventoryData);
