import React, { useState } from "react";
import PricingCards from "./PricingCards";
import { DarkArrow } from "../assets/icons/Icons";
import shopify from "../assets/shopify.png";
import { usePublicAppContext } from "../contexts/AppProvider";

const planCategories = ["Monthly", "Yearly"];

const PricingPlan = () => {
  const { plansState } = usePublicAppContext();
  const [selected, setSelected] = useState("Monthly");

  return (
    <section className="ced-pricing-plan ced-section-space">
      <div className="ced-container">
        <div className="ced-pricing-header">
          <h2 className="ced-section-head">
            Subscription Plans &<span> Offers </span>
          </h2>
          <button
            title="Install App"
            onClick={() => {
              window.open(
                "https://apps.shopify.com/etsy-marketplace-integration"
              );
            }}
            className="ced-btn ced-btn-action ced-animate-action"
          >
            <img width={28} height={32} src={shopify} alt="shopify" /> Install
            App{" "}
            <span className="ced-arrow-icon">
              <DarkArrow />
            </span>
          </button>
        </div>

        <div className="ced-pricing-switcher-wrapper">
          <div className="ced-pricing-switcher-container">
            <div className="ced-pricing-switcher">
              {planCategories.map((item: any) => (
                <button
                  title={item}
                  key={item}
                  className={`ced-pricing-switcher-btn ${
                    selected === item ? "ced-active" : ""
                  }`}
                  onClick={() => setSelected(item)}
                >
                  {item}
                </button>
              ))}
            </div>
            <div className="ced-offer-arrow">
              <span className="ced-offer-percent">
                Save {plansState.yearlyDiscount}% off on yearly plan
              </span>
            </div>
          </div>
        </div>

        <PricingCards selected={selected} />

        <div className="ced-tailored-plan">
          <div className="ced-tailored-plan-content">
            <h3>Looking for a personalized plan? Go Custom With Us!</h3>
            <p>
              Let’s create a tailored plan to match your unique needs. Custom
              Plans are perfect for businesses of any stage or size, helping you
              subscribe with confidence.
            </p>
          </div>
          <div className="ced-tailored-plan-action">
            <button
              title="Contact Us"
              onClick={() => {
                window.open(
                  "https://support.cedcommerce.com/portal/en/newticket"
                );
              }}
              className="ced-cta-connect"
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingPlan;
