import React, { useMemo, useState } from "react";
import {
  Banner,
  BlockStack,
  Button,
  Card,
  InlineStack,
  Link,
  Select,
  Text,
  TextField,
  useBreakpoints,
} from "@shopify/polaris";
import {
  dimensionUnitOptions,
  weightUnitOptions,
} from "Src/Constant/SelectOptions";
import { formatRuleGroup } from "../CreateProfileHelper";
import { Trans } from "react-i18next";
import { DIProps } from "Src/Interface/@core";
import { DI } from "Src/core";
import { useProfileContext } from "../ProfileProvider";
import { regexExpressions } from "Src/Constants";
import { BulkEditProvider } from "Src/Component/BulkEdit/BulkEditProvider";
import SetDimensionsModal from "./Modal/SetDimensionsModal";
import { useRoutesContext } from "Src/Component/RoutesProvider";

const CalculatedShipping = ({ t }: DIProps) => {
  const {
    error,
    defaultDimensions,
    totalProduct,
    rule_group,
    removeError,
    updateDefaultDimensions,
  } = useProfileContext();
  const { mdUp } = useBreakpoints();
  const { routeData } = useRoutesContext()
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const ruleGroupPayload = useMemo(() => {
    return {
      product_profile: {
        condition: rule_group?.condition,
        ...formatRuleGroup(rule_group?.ruleRows),
      },
    };
  }, [rule_group]);

  const changeCalculatedShipping = (key: string, val: string) => {
    if (
      !key.includes("unit") &&
      !regexExpressions.NUMBER_CHECK_WITH_DOT.test(val) &&
      val !== ""
    ) {
      return;
    }
    removeError(`default_dimension_${key}`);
    updateDefaultDimensions({ [key]: val });
  };

  const btnContent: any = (
    <Trans
      i18nKey="SEE_ALL_PRODUCTS"
      values={{ count: (totalProduct ?? 0)?.toString() }}
    />
  );

  return (
    <>
      <Card roundedAbove="xs">
        <BlockStack gap="300">
          <BlockStack gap="100">
            <div className="custom_required">
              <Text fontWeight="medium" as="h2" variant="bodyMd">
                {t("CALCULATED_SHIPPING_DETAILS")}
              </Text>
            </div>
            <Text as="p" variant="bodyMd">
              <Trans
                i18nKey="CALCULATED_SHIPPING_DETAILS_INFO"
                components={[
                  <Link
                    monochrome
                    url="https://help.shopify.com/manual"
                    target="_blank"
                    key={0}
                  />,
                ]}
              />
            </Text>
          </BlockStack>
          <Text fontWeight="medium" as="h2" variant="bodyMd">
            {t("APPLY_SAME_TO_ALL_PRODUCTS")}:{" "}
          </Text>
          <BlockStack gap="200">
            <InlineStack gap="300" wrap={!mdUp}>
              <InlineStack gap="100" wrap={false}>
                <TextField
                  id="default_dimension_weight"
                  labelHidden
                  label="Weight"
                  placeholder="Weight"
                  value={defaultDimensions?.weight}
                  onChange={(val) => {
                    changeCalculatedShipping("weight", val);
                  }}
                  error={error?.["default_dimension_weight"]}
                  autoComplete="off"
                  disabled={routeData?.data?.type === "view"}
                />
                <Select
                  id="default_dimension_weight_unit"
                  labelHidden
                  label=""
                  placeholder={t("SELECT_UNIT")}
                  options={weightUnitOptions}
                  value={defaultDimensions?.weight_unit}
                  onChange={(val) => {
                    changeCalculatedShipping("weight_unit", val);
                  }}
                  error={error?.["default_dimension_weight_unit"]}
                  disabled={routeData?.data?.type === "view"}
                />
              </InlineStack>
              {mdUp && <span className="vertical-divider"></span>}
              <InlineStack gap="100" wrap={false}>
                <TextField
                  id="default_dimension_length"
                  labelHidden
                  label="Length"
                  placeholder="Length"
                  value={defaultDimensions?.length}
                  onChange={(val) => {
                    changeCalculatedShipping("length", val);
                  }}
                  error={error?.["default_dimension_length"]}
                  autoComplete="off"
                  disabled={routeData?.data?.type === "view"}
                />
                <TextField
                  id="default_dimension_width"
                  labelHidden
                  label="Width"
                  placeholder="Width"
                  value={defaultDimensions?.width}
                  onChange={(val) => {
                    changeCalculatedShipping("width", val);
                  }}
                  autoComplete="off"
                  error={error?.["default_dimension_width"]}
                  disabled={routeData?.data?.type === "view"}
                />
                <TextField
                  id="default_dimension_height"
                  labelHidden
                  label="Height"
                  placeholder="Height"
                  value={defaultDimensions?.height}
                  onChange={(val) => {
                    changeCalculatedShipping("height", val);
                  }}
                  error={error?.["default_dimension_height"]}
                  autoComplete="off"
                  disabled={routeData?.data?.type === "view"}
                />
                <Select
                  id="default_dimension_unit"
                  labelHidden
                  label=""
                  placeholder={t("SELECT_UNIT")}
                  value={defaultDimensions?.unit}
                  options={dimensionUnitOptions}
                  onChange={(val) => {
                    changeCalculatedShipping("unit", val);
                  }}
                  error={error?.["default_dimension_unit"]}
                  disabled={routeData?.data?.type === "view"}
                />
              </InlineStack>
            </InlineStack>
          </BlockStack>
          {totalProduct ? (
            <Banner title={t("VALUES_MAY_DIFFER_BETWEEN_PRODUCTS")}>
              <BlockStack gap="300" inlineAlign="start">
                <Text variant="bodyMd" as="p">
                  {" "}
                  {t("VALUES_MAY_DIFFER_BETWEEN_PRODUCTS_INFO")}
                </Text>
                <Button
                  onClick={() => {
                    setIsBulkModalOpen(true);
                  }}
                  disabled={routeData?.data?.type === "view"}          
                >
                  {btnContent}
                </Button>
              </BlockStack>
            </Banner>
          ) : null}
        </BlockStack>
      </Card>
      {isBulkModalOpen && (
        <BulkEditProvider>
          <SetDimensionsModal
            isOpen={isBulkModalOpen}
            toggleSeeAllProductsModal={() =>
              setIsBulkModalOpen((prev) => !prev)
            }
            ruleGroupPayload={ruleGroupPayload}
          />
        </BulkEditProvider>
      )}
    </>
  );
};

export default DI(CalculatedShipping);
