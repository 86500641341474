import React, { useState } from "react";
import {
  Banner,
  Bleed,
  BlockStack,
  Button,
  Card,
  Checkbox,
  Divider,
  Icon,
  InlineGrid,
  InlineStack,
  Link,
  List,
  Select,
  Text,
  TextField,
  useBreakpoints,
} from "@shopify/polaris";
import { CashDollarIcon, RefreshIcon } from "@shopify/polaris-icons";
import {
  DIProps,
  ObjI,
  ObjStrI,
  SelectOptionI,
} from "../../../Interface/@core";
import { DI } from "../../../core";
import {
  deepCloneObj,
  getOptionsFromObj,
  isConfigChecked,
  removeUncheckConfigKeys,
} from "Src/function";
import CustomMultiSelect from "Src/Component/Profilling/CreateProfile/components/CustomMultiSelect";
import { apiUrls } from "Src/Constant/url";
import { ToastAppBridge } from "Src/Component/HelperComponents/Toast";
import { saveLocationMap } from "Src/Redux/Slices/settingsSlice";
import { Trans } from "react-i18next";
import { externalRedirectionUrls } from "Src/Constants";

interface PropsI extends DIProps {
  config: any;
  changeHandlerConfig: (
    key: string,
    toBeToggled: boolean,
    value?: string | ObjI | string[],
    parentKey?: string
  ) => void;
  multiLocationEnabled: boolean;
  errorsObj?: ObjStrI;
  removeError?: (key: string) => void;
  updateConfig?: (obj: any) => void;
  isOnboarding?: boolean;
}

const ProductManagement = (_props: PropsI) => {
  const {
    request: { GET },
    t,
    config,
    dispatch,
    changeHandlerConfig,
    multiLocationEnabled,
    updateConfig,
    errorsObj,
    removeError,
    isOnboarding = false,
    redux,
  } = _props;
  const [isLocationSyncLoading, setIsLocationSyncLoading] = useState(false);
  const [filtersState, setFiltersState] = useState<{
    optionsMap: { [key: string]: ObjStrI };
    loading: boolean;
  }>({
    optionsMap: {},
    loading: false,
  });
  const { mdUp } = useBreakpoints();
  const setDefaultConversionRate = () => {
    const { Currency } = window as any;
    if (Currency?.convert) {
      var convertedCurrency = Currency?.convert(
        "1",
        config.shopify_currency,
        config.etsy_currency
      );
      changeHandlerConfig(
        "currency_conversion_rate",
        false,
        convertedCurrency.toFixed(2)
      );
    }
  };

  const productManagementSyncChecks = [
    { label: t("TITLE"), key: "etsy_title" },
    { label: t("TAGS"), key: "etsy_tags" },
    { label: t("DESCRIPTION"), key: "etsy_description" },
    { label: t("MATERIAL"), key: "etsy_material" },
    { label: t("SHIPPING_PROFILE"), key: "etsy_shipping_profile" },
    { label: t("VIDEO"), key: "etsy_video" },
  ];
  const variationManagementSyncChecks = [
    { label: t("PRICE"), key: "etsy_price" },
    { label: t("INVENTORY"), key: "etsy_inventory" },
  ];

  const automaticSyncingChecks = [
    { label: t("TITLE"), key: "title" },
    { label: t("IMAGE"), key: "images" },
    { label: t("TAGS"), key: "tags" },
    { label: t("INVENTORY"), key: "inventory" },
    { label: t("WEIGHT"), key: "weight" },
    { label: t("PRICE"), key: "price" },
    { label: t("DESCRIPTION"), key: "description" },
    { label: t("IS_DIGITAL"), key: "requires_shipping" },
    { label: t("VIDEO"), key: "shopify_videos" },
  ];

  const inactivateSyncChecks = [
    {
      label: t("DELETED_FROM_SHOPIFY_STORE"),
      key: "product_delete_marketplace",
    },
    { label: t("UNPUBLISHED_FROM_SHOPIFY"), key: "delete_when_unpublished" },
    { label: t("ARCHIVED_FROM_SHOPIFY"), key: "delete_when_archived" },
  ];

  const locationOptions = getOptionsFromObj(redux?.settings?.locationMap ?? {});

  const syncLocations = () => {
    setIsLocationSyncLoading(true);
    GET(apiUrls.syncLocations)
      .then((res) => {
        if(res?.success) {
          fetchLocationMap();
        } else {
          setIsLocationSyncLoading(false);
        }
        ToastAppBridge(res?.message ?? res?.msg, { isError: !res?.success });
      })
  };

  const fetchFilterOptions = (
    option: string = config?.product_create_options ?? ""
  ) => {
    if (filtersState.optionsMap?.[option]) {
      return;
    }
    setFiltersState((prev) => ({ ...prev, loading: true }));
    const params = { option_type: option ?? config?.product_create_options };
    GET(apiUrls.getSettingFilterOptions, params)
      .then((res) => {
        if (res?.success) {
          const reverseMap = Object.entries(res?.data as ObjStrI).reduce(
            (prev, curr) => {
              return { ...prev, [curr?.[1] as string]: curr?.[0] };
            },
            {}
          );
          setFiltersState((prev) => ({
            ...prev,
            optionsMap: {
              ...prev.optionsMap,
              [option]: reverseMap,
            },
          }));
        } else {
          ToastAppBridge(res?.message ?? res?.msg, { isError: !res?.success });
        }
      })
      .finally(() => {
        setFiltersState((prev) => ({ ...prev, loading: false }));
      });
  };

  const filterOptions = filtersState?.optionsMap?.[
    config?.product_create_options
  ]
    ? getOptionsFromObj(
        filtersState?.optionsMap?.[config?.product_create_options]
      )
    : [];

  const changeImportSettings = (key: string, val: string | string[]) => {
    const tempConfig = deepCloneObj(config);
    if (key === "product_create_enable") {
      Object.assign(tempConfig, {
        product_create_enable: val,
        product_create_type: config?.product_create_type ?? "all",
      });
    } else if (key === "product_create_options") {
      Object.assign(tempConfig, {
        product_create_options: val,
        product_options: [],
      });
      fetchFilterOptions(val as string);
    }
    updateConfig?.({ ...tempConfig });
  };

  const languageOptions: SelectOptionI[] =
    redux.userDetails?.etsyShop?.languages?.map((lang: string) => ({
      label: lang,
      value: lang,
    })) ?? [];

  const fetchLocationMap = () => {
    GET(apiUrls.getLocationData).then((res) => {
      if (res?.success) {
        dispatch(saveLocationMap(res?.data?.all_locations ?? {}));
      }
    }).finally(()=>{
      setIsLocationSyncLoading(false);
    })
  };

  return (
    <Card>
      <BlockStack gap="400">
        <BlockStack gap="400">
          <BlockStack gap="200">
            <Text as="h2" variant="headingSm">
              {t("PRODUCT_MANAGEMENT")}
            </Text>
            {!isOnboarding && (
              <Text as="p">{t("MANAGE_AND_CONTROL_PRODUCT_DETAILS")}</Text>
            )}
          </BlockStack>
          <Bleed marginInline="400">
            <Divider />
          </Bleed>
        </BlockStack>

        <BlockStack gap="200">
          <BlockStack gap="200">
            <Text as="p" variant="headingSm" tone="subdued">
              {t("SHOPIFY_SETTINGS")}
            </Text>

            <BlockStack gap={"200"}>
              <InlineStack gap="300" wrap={false}>
                <div className="inte-custom__switcher">
                  <Checkbox
                    label
                    checked={isConfigChecked(config?.["sync_product_enable"])}
                    onChange={() => {
                      changeHandlerConfig("sync_product_enable", true);
                    }}
                  />
                </div>
                <BlockStack gap={"200"}>
                  <Text as="p" variant="bodyMd" fontWeight="medium">
                    {t("PRODUCT_DETAILS_SYNC_SETTNGS_SHOPIFY_TO_APP")}:
                  </Text>
                  <Text as="p">
                    {t("PRODUCT_DETAILS_SYNC_SETTNGS_SHOPIFY_TO_APP_DESC")}
                  </Text>
                  {isConfigChecked(config?.["sync_product_enable"]) && (
                    <InlineGrid columns={2}>
                      {automaticSyncingChecks.map((checkItem) => (
                        <Checkbox
                          label={checkItem.label}
                          checked={isConfigChecked(
                            config?.["sync-fields"]?.[checkItem.key]
                          )}
                          onChange={() => {
                            changeHandlerConfig(
                              "sync-fields",
                              false,
                              removeUncheckConfigKeys(
                                config?.["sync-fields"],
                                checkItem.key
                              )
                            );
                          }}
                        />
                      ))}
                    </InlineGrid>
                  )}
                </BlockStack>
              </InlineStack>

              {!isOnboarding && (
                <BlockStack gap="200">
                  <Divider />
                  <InlineStack gap="300" wrap={false}>
                    <div className="inte-custom__switcher">
                      <Checkbox
                        label
                        checked={isConfigChecked(
                          config?.["product_create_enable"]
                        )}
                        onChange={() => {
                          changeImportSettings(
                            "product_create_enable",
                            isConfigChecked(config?.product_create_enable)
                              ? "0"
                              : "1"
                          );
                        }}
                      />
                    </div>
                    <BlockStack gap={"200"}>
                      <Text as="p" variant="bodyMd" fontWeight="medium">
                        {t("AUTO_PRODUCT_IMPORT")}
                      </Text>
                      <Text as="p">{t("AUTO_PRODUCT_IMPORT_DESC")}</Text>
                      {isConfigChecked(config?.["product_create_enable"]) && (
                        <BlockStack gap="200">
                          <Select
                            label={t("SELECT_OPTION_TO_SPECIFY_IMPORT_TYPE")}
                            placeholder="Select"
                            value={config?.product_create_type}
                            options={[
                              { value: "all", label: t("ALL_PRODUCT_IMPORT") },
                              {
                                value: "published",
                                label: t("PUBLISHED_PRODUCT_IMPORT"),
                              },
                              { value: "custom", label: t("APPLY_FILTER_FOR") },
                            ]}
                            onChange={(val) =>
                              changeHandlerConfig(
                                "product_create_type",
                                false,
                                val
                              )
                            }
                            error={errorsObj?.product_create_type}
                          />
                          {config?.product_create_type === "custom" && (
                            <>
                              <Select
                                label={t(
                                  "SELECT_OPTION_TO_SPECIFY_PRODUCT_OPTIONS"
                                )}
                                placeholder="Select"
                                value={config?.product_create_options}
                                options={[
                                  {
                                    value: "product_type",
                                    label: t("PRODUCT_TYPE"),
                                  },
                                  { value: "vendor", label: t("VENDOR") },
                                ]}
                                onChange={(val) => {
                                  changeImportSettings(
                                    "product_create_options",
                                    val
                                  );
                                  removeError?.("product_create_options");
                                }}
                                error={errorsObj?.product_create_options}
                              />
                              <CustomMultiSelect
                                label={t(
                                  "SELECT_OPTION_TO_SPECIFY_AUTO_PRODUCT_IMPORT"
                                )}
                                placeholder="Select"
                                options={filterOptions}
                                value={config?.["product_options"]}
                                onChange={(val) => {
                                  removeError?.("product_options");
                                  changeHandlerConfig(
                                    "product_options",
                                    false,
                                    val
                                  );
                                }}
                                error={errorsObj?.product_options}
                                noCustomValue
                                loading={filtersState.loading}
                              />
                            </>
                          )}
                        </BlockStack>
                      )}
                    </BlockStack>
                  </InlineStack>
                </BlockStack>
              )}

              {!isOnboarding &&
                isConfigChecked(config?.custom_setting?.metafield_enable) && (
                  <BlockStack gap="200">
                    <Divider />
                    <InlineStack gap="300" wrap={false}>
                      <div className="inte-custom__switcher">
                        <Checkbox
                          label
                          checked={isConfigChecked(
                            config?.metafield_sync_enable
                          )}
                          onChange={() =>
                            changeHandlerConfig("metafield_sync_enable", true)
                          }
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <BlockStack gap={"200"}>
                          <Text as="p" variant="bodyMd" fontWeight="medium">
                            {t("SYNC_METAFIELDS_FROM_SHOPIFY")}
                          </Text>

                          <div className="combobox-tags">
                            <BlockStack gap="200">
                              <Text as="p">
                                {t("ENABLE_METAFIELD_SYNCING_FROM_SHOPIFY")}
                              </Text>

                              {isConfigChecked(
                                config?.metafield_sync_enable
                              ) && (
                                <CustomMultiSelect
                                  placeholder={t("ENTER_METAFIELD")}
                                  onChange={(val) => {
                                    changeHandlerConfig(
                                      "metafield_namespaces",
                                      false,
                                      val
                                    );
                                  }}
                                  value={config?.metafield_namespaces}
                                  options={[]}
                                  helpText={
                                    <List>
                                      <List.Item>
                                        {t("ADD_UPTO_EIGHT_TAGS")}
                                      </List.Item>
                                      <List.Item>
                                        {t(
                                          "ADD_NAMESPACES_OF_SHOPIFY_METAFIELDS"
                                        )}
                                      </List.Item>
                                    </List>
                                  }
                                />
                              )}
                            </BlockStack>
                          </div>
                        </BlockStack>
                      </div>
                    </InlineStack>
                  </BlockStack>
                )}

              {!isOnboarding &&
                multiLocationEnabled &&
                locationOptions.length > 1 && (
                  <>
                    <Divider />
                    <div className="padding-left44">
                      <BlockStack gap="200">
                        <Text as="p" fontWeight="medium">
                          {t("SHOPIFY_PRODUCT_LOCATION")}
                        </Text>
                        <Text as="p">{t("SHOPIFY_PRODUCT_LOCATION_DESC")}</Text>

                        <Select
                          options={locationOptions}
                          label={t("SELECT_ACTIVE_LOCATION")}
                          labelHidden
                          placeholder={t("SELECT_ACTIVE_LOCATION")}
                          value={config?.active_location}
                          onChange={(val) =>
                            changeHandlerConfig("active_location", false, val)
                          }
                        ></Select>
                      </BlockStack>
                    </div>
                  </>
                )}

              {/* Etsy Languages  */}

              {!isOnboarding && languageOptions?.length > 1 && (
                <BlockStack gap="200">
                  <Divider />
                  <div className="padding-left44">
                    <BlockStack gap="200">
                      <Text as="p" fontWeight="medium">
                        {t("ETSY_LANGUAGE")}
                      </Text>
                      <Text as="p">{t("ETSY_LANGUAGE_DESC")}</Text>
                      <Banner>{t("ETSY_LANGUAGE_BANNER")}</Banner>

                      <Select
                        options={languageOptions}
                        label={t("SELECT_LANGUAGE")}
                        labelHidden
                        placeholder={t("SELECT_LANGUAGE")}
                        value={config?.language_management}
                        onChange={(val) => {
                          changeHandlerConfig(
                            "language_management",
                            false,
                            val
                          );
                        }}
                      ></Select>
                    </BlockStack>
                  </div>
                </BlockStack>
              )}
            </BlockStack>
          </BlockStack>

          <Bleed marginInlineStart="400" marginInlineEnd="400">
            <Divider />
          </Bleed>

          {/* new settings update end */}

          <BlockStack gap="200">
            <BlockStack gap="200">
              {!isOnboarding && (
                <>
                  {" "}
                  <Text as="h3" variant="headingSm" tone="subdued">
                    {t("ETSY_SETTINGS")}
                  </Text>
                  {/* new settings update start */}
                  <div className="padding-left44">
                    <BlockStack gap={"200"}>
                      <BlockStack gap="100">
                        <InlineStack align="space-between">
                          <Text as="p" variant="bodyMd" fontWeight="medium">
                            {t("SHOPIFY_LOCATIONS")}
                          </Text>
                          {multiLocationEnabled ? (
                            <Button
                              icon={<Icon source={RefreshIcon} tone="base" />}
                              loading={isLocationSyncLoading}
                              onClick={syncLocations}
                            >
                              {t("REFRESH")}
                            </Button>
                          ) : null}
                        </InlineStack>
                        <Text as="p">{t("SHOPIFY_LOCATION_DESC")}</Text>
                      </BlockStack>
                      {multiLocationEnabled ? (
                        <Card roundedAbove="xs">
                          <div className="shopify-locations-wrapper">
                            <InlineGrid columns={mdUp ? 2 : 1}>
                              {locationOptions.map((option) => (
                                <Checkbox
                                  label={option.label}
                                  checked={isConfigChecked(
                                    config?.locations_management?.[option.value]
                                  )}
                                  onChange={() =>
                                    changeHandlerConfig(
                                      "locations_management",
                                      false,
                                      removeUncheckConfigKeys(
                                        config?.["locations_management"],
                                        option.value
                                      )
                                    )
                                  }
                                  disabled={
                                    isConfigChecked(
                                      config?.locations_management?.[
                                        option.value
                                      ]
                                    ) && (Object.keys(config?.locations_management)?.length === 1 || locationOptions.length === 1)
                                  }
                                />
                              ))}
                            </InlineGrid>
                          </div>
                        </Card>
                      ) : (
                        <Banner tone="info">
                          <Text as="p" fontWeight="semibold">
                            <Trans
                              i18nKey="ENABLE_CONTACT_SUPPORT"
                              components={[
                                <Link
                                  key="contact_support"
                                  target="_blank"
                                  url={externalRedirectionUrls.ContactSupport}
                                />,
                              ]}
                            />
                          </Text>
                        </Banner>
                      )}
                    </BlockStack>
                  </div>
                  <Divider />
                </>
              )}
              {/* new settings update end */}

              {isOnboarding && (
                <Text as="p" variant="headingSm" tone="subdued">
                  {t("ETSY_SETTINGS")}
                </Text>
              )}
              <InlineStack gap="300" wrap={false}>
                <div className="inte-custom__switcher">
                  <Checkbox
                    label
                    checked={isConfigChecked(
                      config?.["enable_product_management_with_etsy"]
                    )}
                    onChange={() => {
                      changeHandlerConfig(
                        "enable_product_management_with_etsy",
                        true
                      );
                    }}
                  />
                </div>
                <BlockStack gap={"200"}>
                  <Text as="p" variant="bodyMd" fontWeight="medium">
                    {t("PRODUCT_DETAILS_SYNC_SETTNGS_APP_TO_SHOPIFY")}
                  </Text>
                  <Text as="p">
                    {t("PRODUCT_DETAILS_SYNC_SETTNGS_APP_TO_SHOPIFY_DESC")}
                  </Text>
                  <Text as="p">
                    {t("NOTE")}: {t("PRODUCT_DETAILS_SYNC_SETTNGS_APP_TO_SHOPIFY_DESC_NOTE")}
                  </Text>
                  {isConfigChecked(
                    config?.["enable_product_management_with_etsy"]
                  ) && (
                    <InlineGrid columns={2}>
                      {productManagementSyncChecks.map((check) => (
                        <Checkbox
                          label={check.label}
                          checked={isConfigChecked(
                            config?.["etsy-sync-fields"]?.[check.key]
                          )}
                          onChange={() => {
                            changeHandlerConfig(
                              "etsy-sync-fields",
                              false,
                              removeUncheckConfigKeys(
                                config?.["etsy-sync-fields"],
                                check.key
                              )
                            );
                          }}
                        />
                      ))}
                    </InlineGrid>
                  )}
                </BlockStack>
              </InlineStack>
              <Divider />
              <InlineStack gap="300" wrap={false}>
                <div className="inte-custom__switcher">
                  <Checkbox
                    label
                    checked={isConfigChecked(
                      config?.["enable_variation_management_with_etsy"]
                    )}
                    onChange={() => {
                      changeHandlerConfig(
                        "enable_variation_management_with_etsy",
                        true
                      );
                    }}
                  />
                </div>
                <BlockStack gap={"200"}>
                  <Text as="p" variant="bodyMd" fontWeight="medium">
                    {t("VARIATION_MANAGEMENT_ON_ETSY")}
                  </Text>
                  <Text as="p">
                    {t("VARIATION_MANAGEMENT_ON_ETSY_DESC")}
                  </Text>
                  {isConfigChecked(
                    config?.["enable_variation_management_with_etsy"]
                  ) && (
                    <BlockStack>
                      {variationManagementSyncChecks.map((checkItem) => (
                        <Checkbox
                          label={checkItem.label}
                          checked={isConfigChecked(
                            config?.["etsy-variation-sync-fields"]?.[
                              checkItem.key
                            ]
                          )}
                          onChange={() => {
                            changeHandlerConfig(
                              "etsy-variation-sync-fields",
                              false,
                              removeUncheckConfigKeys(
                                config?.["etsy-variation-sync-fields"],
                                checkItem.key
                              )
                            );
                          }}
                        />
                      ))}
                    </BlockStack>
                  )}
                </BlockStack>
              </InlineStack>
              <Divider />
              {config?.shopify_currency !== config?.etsy_currency ? (
                <BlockStack gap="200">
                  <div className="padding-left44">
                    <BlockStack gap={"200"}>
                      <Text as="p" variant="bodyMd" fontWeight="medium">
                        {t("CURRENCY_CONVERSION")}
                      </Text>
                      <Text as="p">{t("CURRENCY_CONVERSION_DESC")}</Text>

                      <div className="inte-currency__conversion">
                        <TextField
                          autoComplete=""
                          label={
                            <InlineStack
                              align="space-between"
                              blockAlign="center"
                            >
                              <Text as="p" variant="bodyMd">
                                {t("CONVERSION_RATE")}
                              </Text>
                              <Button
                                size="micro"
                                icon={RefreshIcon}
                                onClick={setDefaultConversionRate}
                              >
                                {t("FETCH_CONVERSION_RATE")}
                              </Button>
                            </InlineStack>
                          }
                          prefix="1 ₹ ="
                          suffix={<CashDollarIcon width={20} height={20} />}
                          value={config?.["currency_conversion_rate"]}
                          onChange={(val) => {
                            changeHandlerConfig(
                              "currency_conversion_rate",
                              false,
                              val
                            );
                          }}
                          helpText={
                            <Text variant="bodyMd" as="p" tone="subdued">
                              {t("Your Shopify store currency is")}{" "}
                              <Text as="span" fontWeight="medium">
                                {config.shopify_currency}
                              </Text>
                              . {t("APP_AUTOMATICALLY_COVERT_TO_ETSY_CURRENCY")}{" "}
                              <Text as="span" fontWeight="medium">
                                {config.etsy_currency}
                              </Text>
                              . {t("VERIFY_AND_UPDATE")}
                            </Text>
                          }
                        />
                      </div>
                    </BlockStack>
                  </div>
                  <Divider />
                </BlockStack>
              ) : null}

              <div className="padding-left44">
                <BlockStack gap={"200"}>
                  <Text as="p" variant="bodyMd" fontWeight="medium">
                    {t("AUTO_INACTIVE_LISTING_ON_ETSY")}
                  </Text>
                  <Text as="p">{t("AUTO_INACTIVE_LISTING_ON_ETSY_DESC")}</Text>
                  <Banner tone="info">
                    {t("INACTIVATE_LISTING_ETSY_INFO")}
                  </Banner>
                  <BlockStack>
                    {inactivateSyncChecks.map((checkItem) => (
                      <Checkbox
                        label={checkItem.label}
                        checked={isConfigChecked(config?.[checkItem.key])}
                        onChange={() => {
                          changeHandlerConfig(checkItem.key, true);
                        }}
                      />
                    ))}
                  </BlockStack>
                </BlockStack>
              </div>
            </BlockStack>
          </BlockStack>
        </BlockStack>
      </BlockStack>
    </Card>
  );
};

export default DI(ProductManagement);
