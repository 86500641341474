import React from 'react';

export const PlusIcon = (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 8.98755V16.9875M8 12.9875H16M22 12.9875C22 18.5104 17.5228 22.9875 12 22.9875C6.47715 22.9875 2 18.5104 2 12.9875C2 7.4647 6.47715 2.98755 12 2.98755C17.5228 2.98755 22 7.4647 22 12.9875Z" stroke="#666666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)


export const MinusIcon = (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 12.9875H16M22 12.9875C22 18.5104 17.5228 22.9875 12 22.9875C6.47715 22.9875 2 18.5104 2 12.9875C2 7.4647 6.47715 2.98755 12 2.98755C17.5228 2.98755 22 7.4647 22 12.9875Z" stroke="#413BBC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)


export const CheckIcon = (<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.4054 10.2377L10.8034 16.8397L7.80243 13.8388" stroke="#66ED78" strokeWidth="1.20038" strokeLinecap="round" strokeLinejoin="round" />
</svg>)

export const CrossIcon = (<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.85394 8.97266L16.3539 16.4727M16.3539 8.97266L8.85394 16.4727" stroke="#7B7B7B" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
</svg>)


export const ChevronDown = (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.0981 9.81867L12.7605 16.1563L6.42291 9.81867" stroke="#444444" strokeWidth="2.11253" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

)

export const ChevronUp = (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.9019 16.1813L13.2395 9.8437L19.5771 16.1813" stroke="#444444" strokeWidth="2.11253" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

)

export const DarkArrow = ({size}:any) => (
    <svg width={size ?? 27} height={size ?? 27} viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.966675" width="27" height="27" rx="13.5" fill="black" />
        <path d="M9.4502 18.5167L16.2002 11.7667" stroke="white" strokeWidth="1.35" strokeLinecap="square" />
        <path d="M9.4502 11.0917H16.8752V18.5167" stroke="white" strokeWidth="1.35" strokeLinecap="square" strokeLinejoin="round" />
    </svg>
)

export const CrossIconMark = (
    <svg fill="none" strokeWidth="0" viewBox="0 0 15 15" height="34" width="34" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z" fill="#fff"></path></svg>
)

export const HamburgurIcon = (
    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 15 15" height="34" width="34" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z" fill="currentColor"></path></svg>
)