import React from 'react';

import CedCommerceTermsConditions from "../components/privacy-policy/CedCommerceTermsConditions";
import CommunicationPolicy from "../components/privacy-policy/CommunicationPolicy";
import HowToDeal from "../components/privacy-policy/HowToDeal";
import LimitationOfLiability from "../components/privacy-policy/LimitationOfLiability";
import NoWarranties from "../components/privacy-policy/NoWarranties";
import PaymentsRefunds from "../components/privacy-policy/PaymentsRefunds";
import PrivacyPolicy from "../components/privacy-policy/PrivacyPolicy";
import PurposeScope from "../components/privacy-policy/PurposeScope";
import UpdationInPersonalInfo from "../components/privacy-policy/UpdationInPersonalInfo";
import UsageOfService from "../components/privacy-policy/UsageOfService";
import WhatAreCookies from "../components/privacy-policy/WhatAreCookies";
import WhenYouCanClaimRefund from "../components/privacy-policy/WhenYouCanClaimRefund";
import WhenYouCantClaimRefund from "../components/privacy-policy/WhenYouCantClaimRefund";
import WhoHasAccess from "../components/privacy-policy/WhoHasAccess";
import YourAccount from "../components/privacy-policy/YourAccount";
import YourInformation from "../components/privacy-policy/YourInformation";
import YourRights from "../components/privacy-policy/YourRights";

export const privacyData = [
    { title: 'CedCommerce Terms & Conditions and Privacy Policy', content: <CedCommerceTermsConditions/> },
    { title: 'Your Information', content: <YourInformation/> },
    { title: 'Who has access to your data within our organization?', content: <WhoHasAccess/> },
    { title: 'Your Account', content: <YourAccount/> },
    { title: 'Payments & Refunds', content: <PaymentsRefunds/> },
    { title: 'When You Can Claim The Refund?', content: <WhenYouCanClaimRefund/> },
    { title: 'When You Cant Claim The Refund?', content: <WhenYouCantClaimRefund/> },
    { title: 'Your rights under the gdpr', content: <YourRights/> },
    { title: 'Usage of the Service', content: <UsageOfService/> },
    { title: 'Communication Policy', content: <CommunicationPolicy/> },
    { title: 'No Warranties', content: <NoWarranties/> },
    { title: 'Limitation of Liability', content: <LimitationOfLiability/> },
    { title: 'Privacy Policy', content: <PrivacyPolicy/> },
    { title: 'Purpose and scope', content: <PurposeScope/> },
    { title: 'How do we deal with 3rd Parties?', content: <HowToDeal/>},
    { title: 'What are Cookies and What Information does it gather?', content: <WhatAreCookies/> },
    { title: 'Updation in Personal Information / Inquiries / Complaints', content: <UpdationInPersonalInfo/> },
  ];