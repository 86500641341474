import React from "react";

const LimitationOfLiability = () => {
  return (
    <div>
        <h2>Limitation of Liability
        </h2>
        <p>
        TO THE FULLEST EXTENT PERMITTED BY LAW IN NO EVENT SHALL CedCommerce.com BE LIABLE FOR ANY INJURY, LOSS, CLAIM, DAMAGE, OR ANY SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND OR FOR ANY LOST PROFITS OR LOST SAVINGS, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), EQUITY, STRICT LIABILITY, STATUTE OR OTHERWISE, WHICH ARISES OUT OF OR IS IN ANY WAY CONN CONTENT FOUND HEREIN, (II) ANY FAILURE OR DELAY (INCLUDING, BUT NOT LIMITED TO THE USE OF OR INABILITY TO USE ANY COMPONENT OF THE SERVICE OR THIS SITE), OR (III) THE PERFORMANCE OR NON PERFORMANCE BY CedCommerce.com EVEN IF THE CedCommerce.com HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES TO SUCH PARTIES OR ANY OTHER PARTY.
        </p>
    </div>
  )
}

export default LimitationOfLiability
