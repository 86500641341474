import {
  Icon,
  IndexTable,
  InlineGrid,
  InlineStack,
  Select,
  TextField,
} from "@shopify/polaris";
import React from "react";
import { dimensionUnitOptions } from "Src/Constant/SelectOptions";
import { DI } from "Src/core";
import useDebounce from "Src/hooks/useDebounce";
import { useBulkEditContext } from "../BulkEditProvider";
import { regexExpressions } from "Src/Constants";
import { AlertTriangleIcon } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import WithTogglabbleTooltip from "Src/Component/HelperComponents/WithTogglabbleTooltip";
import { isValidValue } from "Src/function";

interface DimensionsI {
  height?: string;
  length?: string;
  unit?: string;
  weight?: string;
  weight_unit?: string;
  width?: string;
}
const DimensionsData = ({
  dimensions,
  id,
}: {
  dimensions: string;
  id: string;
}) => {
  const { updatedData, setUpdatedData, error, setError } = useBulkEditContext();
  const {t} = useTranslation();
  let Dimension: DimensionsI = {};
  if (dimensions) {
    Dimension = JSON.parse(dimensions);
  }

  const handleFiltersQueryChange = (key:string , e: string) => {
    const tempUpdatedData = structuredClone(updatedData);
    if (!tempUpdatedData[id]) {
      tempUpdatedData[id] = {
        height: getValue("height"),
        length: getValue("length"),
        width: getValue("width"),
        dimension_unit:getValue("unit")
      };
    }
    tempUpdatedData[id][key] = e;
    setUpdatedData(tempUpdatedData);
  };
  const getValue = (code: keyof DimensionsI) => {
    let tempCode: string = code;
    if (code === "unit") {
      tempCode = "dimension_unit";
    }
    if (updatedData?.[id]?.[tempCode]) {
      return updatedData?.[id]?.[tempCode];
    } else {
      return Dimension[code];
    }
  };

  const {
    changeDebouncedValue: changeDebouncedValueHeight,
    debouncedValue: debouncedValueHeight,
  } = useDebounce((val) => handleFiltersQueryChange('height',val), 700, getValue("height"));
  const {
    changeDebouncedValue: changeDebouncedValueLength,
    debouncedValue: debouncedValueLength,
  } = useDebounce((val) => handleFiltersQueryChange('length',val), 700, getValue("length"));

  const {
    changeDebouncedValue: changeDebouncedValueWidth,
    debouncedValue: debouncedValueWidth,
  } = useDebounce((val) => handleFiltersQueryChange('width',val), 700, getValue("width"));

  const reqErrorSet = (
    current: "length" | "width" | "height" | "unit",
    value: string
  ) => {
    const tempError = structuredClone(error) || {};
    const height = current === "height" ? value : debouncedValueHeight;
    const width = current === "width" ? value : debouncedValueWidth;
    const length = current === "length" ? value : debouncedValueLength;
    const unit = current === "unit" ? value : getValue("unit");
    let flag = false;
    if (
      height?.trim() === "" &&
      width?.trim() === "" &&
      length?.trim() === "" &&
      (unit === "" || unit === undefined)
    ) {
      tempError["length"] = tempError["length"] || {};
      tempError["width"] = tempError["width"] || {};
      tempError["height"] = tempError["height"] || {};
      tempError["unit"] = tempError["unit"] || {};

      if (tempError["length"][id]) {
        delete tempError["length"][id];
        flag = true;
        if (Object.keys(tempError["length"]).length === 0) {
          delete tempError["length"];
        }
      }
      if (tempError["width"][id]) {
        delete tempError["width"][id];
        flag = true;
        if (Object.keys(tempError["width"]).length === 0) {
          delete tempError["width"];
        }
      }
      if (tempError["height"][id]) {
        delete tempError["height"][id];
        flag = true;
        if (Object.keys(tempError["height"]).length === 0) {
          delete tempError["height"];
        }
      }
      if (tempError["unit"][id]) {
        delete tempError["unit"][id];
        flag = true;
        if (Object.keys(tempError["unit"]).length === 0) {
          delete tempError["unit"];
        }
      }
    } else {
      if (
        length?.trim() === "" ||
        parseInt(length) < parseInt(width) ||
        parseInt(length) < parseInt(height)
      ) {
        tempError["length"] = tempError["length"] || {};
        tempError["length"][id] = true;
        flag = true;
      } else {
        if (tempError?.["length"]?.[id] === true) {
          delete tempError["length"][id];
          flag = true;
          if (Object.keys(tempError["length"]).length === 0) {
            delete tempError["length"];
          }
        }
      }
      if (width?.trim() === "") {
        tempError["width"] = tempError["width"] || {};
        tempError["width"][id] = true;
        flag = true;
      } else {
        if (tempError?.["width"]?.[id] === true) {
          delete tempError["width"][id];
          flag = true;
          if (Object.keys(tempError["width"]).length === 0) {
            delete tempError["width"];
          }
        }
      }
      if (height?.trim() === "") {
        tempError["height"] = tempError["height"] || {};
        tempError["height"][id] = true;
        flag = true;
      } else {
        if (tempError?.["height"]?.[id] === true) {
          delete tempError["height"][id];
          flag = true;
          if (Object.keys(tempError["height"]).length === 0) {
            delete tempError["height"];
          }
        }
      }
      if (!isValidValue(unit)) {
        tempError["unit"] = tempError["unit"] || {};
        tempError["unit"][id] = true;
        flag = true;
      } else {
        if (tempError?.["unit"]?.[id] === true) {
          delete tempError["unit"][id];
          flag = true;
          if (Object.keys(tempError["unit"]).length === 0) {
            delete tempError["unit"];
          }
        }
      }
    }
    flag && setError(tempError);
  };

  return (
    <IndexTable.Cell flush className="min-w-350 bg-white dimension-cells">
      <InlineStack  wrap={false}>
        <InlineGrid columns={3}>
          <WithTogglabbleTooltip
          enabledParent={error?.["length"]?.[id] === true}
            text={t("BULK_EDIT_DIMENSION_ERROR")}
            child={
              <TextField
                id={id}
                error={error?.["length"]?.[id] === true}
                prefix="L:"
                label="Length"
                suffix={
                  error?.["length"]?.[id] === true ? (
                    <Icon source={AlertTriangleIcon} />
                  ) : null
                }
                labelHidden
                value={debouncedValueLength}
                onChange={(e) => {
                  if (
                    regexExpressions.NUMBER_CHECK_WITH_DOT.test(e) ||
                    e?.trim() === ""
                  ) {
                    reqErrorSet("length", e);
                    changeDebouncedValueLength(e);
                  }
                }}
                autoComplete="off"
              />
            }
          />

          <TextField
            prefix="W: "
            label="Width"
            labelHidden
            error={error?.["width"]?.[id] === true}
            value={debouncedValueWidth}
            suffix={
              error?.["width"]?.[id] === true ? (
                <Icon source={AlertTriangleIcon} />
              ) : null
            }
            onChange={(e) => {
              if (
                regexExpressions.NUMBER_CHECK_WITH_DOT.test(e) ||
                e?.trim() === ""
              ) {
                reqErrorSet("width", e);

                changeDebouncedValueWidth(e);
              }
            }}
            autoComplete="off"
          />

          <TextField
            prefix="H: "
            label="Height"
            labelHidden
            value={debouncedValueHeight}
            suffix={
              error?.["height"]?.[id] === true ? (
                <Icon source={AlertTriangleIcon} />
              ) : null
            }
            error={error?.["height"]?.[id] === true}
            onChange={(e) => {
              if (
                regexExpressions.NUMBER_CHECK_WITH_DOT.test(e) ||
                e?.trim() === ""
              ) {
                reqErrorSet("height", e);

                changeDebouncedValueHeight(e);
              }
            }}
            autoComplete="off"
          />
        </InlineGrid>
        <div className="custom-unit-select">
          <Select
            error={error?.["unit"]?.[id] === true}
            onChange={(e) => {
              handleFiltersQueryChange("dimension_unit",e);
              reqErrorSet("unit", e);
            }}
            placeholder="unit"
            label="dimensions"
            value={getValue("unit")}
            labelHidden
            options={dimensionUnitOptions}
          />
        </div>
      </InlineStack>
    </IndexTable.Cell>
  );
};

export default DI(DimensionsData);
