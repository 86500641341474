import React from 'react';
import productlisting from '../assets/product-listing.png'
import inventorymanagement from '../assets/inventory-management.png'
import ordermanagement from '../assets/order-management.png'
import shippingmanagement from '../assets/shipping-management.png'

const FeaturesAndBenefits = () => {
  return (
    <section className="ced-features-and-benefits ced-section-space">
       <div className="ced-container">
              <h2 className="ced-section-head">App Features & <span>Benefits</span></h2>
              <div className="ced-features-and-benefits__row">
                  <div className="ced-features-and-benefits__item">
                    <img width={100} height={105} src={productlisting} alt="Seamless Product Synchronization" title="Seamless Product Synchronization" className="ced-gif__icon"/>
                    <span>01</span>
                    <h3>Seamless Product Synchronization</h3>
                    <p>Effortlessly sync bulk products between Shopify and Etsy to maintain consistency in listing details across sales channels. </p>
                  </div>

                  <div className="ced-features-and-benefits__item">
                    <img src={inventorymanagement} width={100} height={75} alt="Simplified Product Listing" title="Simplified Product Listing" className="ced-gif__icon"/>
                    <span>02</span>
                    <h3>Simplified Product Listing</h3>
                    <p>Reach out to millions of customers easily by refining the listing process with profiling and templates; save time, reduce errors, and ensure consistency.
                    </p>
                  </div>

                  <div className="ced-features-and-benefits__item">
                    <img width={100} height={98} src={ordermanagement} alt="Advanced Order Management" title="Advanced Order Management" className="ced-gif__icon"/>
                    <span>03</span>
                    <h3>Advanced Order Management
                    </h3>
                    <p>Enjoy a streamlined order management system by syncing past, current, and upcoming Etsy orders all from a centralized dashboard.
                    </p>
                  </div>

                  <div className="ced-features-and-benefits__item">
                    <img width={100} height={104} src={shippingmanagement} alt="Near Real-time Synchronization" title="Near Real-time Synchronization" className="ced-gif__icon"/>
                    <span>04</span>
                    <h3>Near Real-time Synchronization</h3>
                    <p>Get near real-time updates on orders, product prices, and inventory levels, ensuring accuracy and minimizing delays.</p>
                  </div>
                </div>
       </div>
    </section>
  )
}



export default FeaturesAndBenefits
