import {
  BlockStack,
  InlineGrid,
  InlineStack,
  TabProps,
  Tabs,
  Tag,
  Text,
} from "@shopify/polaris";
import React, { useMemo, useState } from "react";
import { languagesMap } from "Src/Constant/helpData";
import { DI } from "Src/core";
import { DIProps } from "Src/Interface/@core";

interface PropsI extends DIProps {
  translations?: {
    [key: string]: {
      listing_id?: string;
      language?: string;
      title?: string;
      description?: string;
      tags?: string[];
    };
  };
}

const Translation = ({ translations, t }: PropsI) => {
  const [selected, setSelected] = useState(0);
  // filtering on the basis of invalid object
  const filteredTranslations = useMemo(() => {
    return translations
      ? Object.entries(translations)
          .filter((translation) => translation[1])
          .reduce((prev, curr) => {
            return {
              ...prev,
              [curr[0]]: curr[1],
            };
          }, {})
      : null;
  }, [translations]);
  // mapped object with keys and tab index
  const tabIdMap: { [key: number]: string } = useMemo(() => {
    return filteredTranslations
      ? Object.keys(filteredTranslations)?.reduce((prev, curr, index) => {
          return {
            ...prev,
            [index]: curr,
          };
        }, {})
      : {};
  }, [filteredTranslations]);

  const tabs: TabProps[] = useMemo(() => {
    return filteredTranslations
      ? Object.entries(filteredTranslations).map(([lang, value]) => {
          return {
            id: lang,
            content: languagesMap?.[lang]?.label ?? "",
            accessibilityLabel: lang,
            panelID: languagesMap?.[lang]?.name ?? "",
          };
        })
      : [];
  }, [filteredTranslations]);
  // memoised value for selected tab object
  const selectedTab = useMemo(() => {
    return tabs?.find((tab) => tab?.id === tabIdMap[selected]);
  }, [tabs, selected, tabIdMap]);

  return (
    <>
      <div className="translation-tab">
        <Tabs
          tabs={tabs}
          selected={selected}
          onSelect={(val) => {
            setSelected(val);
          }}
        />
      </div>
      <BlockStack gap="200">
        <InlineGrid
          gap="100"
          columns={{
            lg: ["oneThird", "twoThirds"],
            md: ["oneHalf", "oneHalf"],
          }}
        >
          <Text as="p" variant="bodyMd" fontWeight="medium">
            {`${selectedTab?.content} ${t("TITLE")}`}:
          </Text>
          <Text as="p" variant="bodyMd" fontWeight="regular">
            {translations?.[tabIdMap?.[selected]]?.title ?? "N/A"}
          </Text>
        </InlineGrid>
        <InlineGrid
          gap="100"
          columns={{
            lg: ["oneThird", "twoThirds"],
            md: ["oneHalf", "oneHalf"],
          }}
        >
          <Text as="p" variant="bodyMd" fontWeight="medium">
            {`${selectedTab?.content} ${t("DESCRIPTION")}`}:
          </Text>
          <div
            className="view-description"
            dangerouslySetInnerHTML={{
              __html:
                translations?.[tabIdMap?.[selected]]?.description ?? "N/A",
            }}
          />
        </InlineGrid>
        <InlineGrid
          gap="100"
          columns={{
            lg: ["oneThird", "twoThirds"],
            md: ["oneHalf", "oneHalf"],
          }}
        >
          <Text as="p" variant="bodyMd" fontWeight="medium">
            {`${selectedTab?.content} ${t("TAGS")} (${t("OPTIONAL")})`}:
          </Text>
          {translations?.[tabIdMap?.[selected]]?.tags ? (
            <InlineStack gap="200" blockAlign="center">
              {translations?.[tabIdMap?.[selected]]?.tags?.map((tag) => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </InlineStack>
          ) : (
            "N/A"
          )}
        </InlineGrid>
      </BlockStack>
    </>
  );
};

export default DI(Translation);
