import React, { useRef, useEffect } from 'react';

interface CustomSliderProps {
  min?: number;
  max?: number;
  step?: number;
  orderCounts: number[];
  rangeValue: number;
  setRangeValue: (value: number) => void;
}

const CustomSlider: React.FC<CustomSliderProps> = ({
  min = 0,
  max = 5,
  step = 1,
  orderCounts,
  rangeValue,
  setRangeValue,
}) => {
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const isDraggingRef = useRef(false);

  const handleMove = (clientX: number) => {
    if (!isDraggingRef.current || !sliderRef.current) return;

    const sliderRect = sliderRef.current.getBoundingClientRect();
    let newLeft = clientX - sliderRect.left;

    newLeft = Math.max(0, Math.min(newLeft, sliderRect.width));

    const newValue = Math.round((newLeft / sliderRect.width) * (max - min)) + min;
    const steppedValue = Math.round(newValue / step) * step;
    setRangeValue(Math.max(min, Math.min(steppedValue, max)));
  };

  const handleMouseMove = (e: MouseEvent) => handleMove(e.clientX);
  const handleTouchMove = (e: TouchEvent) => handleMove(e.touches[0].clientX);

  const stopDragging = () => {
    isDraggingRef.current = false;
  };

  const handleMouseDown = () => {
    isDraggingRef.current = true;
  };

  const handleTouchStart = () => {
    isDraggingRef.current = true;
  };

  const handleSliderClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!sliderRef.current) return;

    const sliderRect = sliderRef.current.getBoundingClientRect();
    const newLeft = e.clientX - sliderRect.left;

    const newValue = Math.round((newLeft / sliderRect.width) * (max - min)) + min;
    const steppedValue = Math.round(newValue / step) * step;
    setRangeValue(Math.max(min, Math.min(steppedValue, max)));
  };

  const thumbPosition = ((rangeValue - min) / (max - min)) * 100;

  // Add event listeners for dragging
  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', stopDragging);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', stopDragging);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', stopDragging);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', stopDragging);
    };
  }, [min, max, step]);

  return (
      <div className='ced-custom-range'>
        <div
          className="slider-container"
          ref={sliderRef}
          onClick={handleSliderClick}
        >
          <div className="slider" style={{ width: `${rangeValue === 1 ? thumbPosition+2 : thumbPosition}%` }}></div>
          <div
            className="thumb"
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
            role="slider"
            tabIndex={0}
            aria-label="slider"
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={rangeValue}
          />

        <ul className="ced-order-marker-list">
            {orderCounts.map((_, index) => {
              const position = (index / (orderCounts.length - 1)) * 100;
              return (
                <li
                  key={index}
                  className={`order-bullet  bullet-${index}`}
                  style={{ left: `${position}%` }}
                >
                <span className='ced-vertical-marker'></span>
                </li>
              );
            })}
          </ul>
          {/* Render bullet list at precise positions */}
          <ul className="ced-order-bullet-list">
            {orderCounts.map((count, index) => {
              const position = (index / (orderCounts.length - 1)) * 100;
              return (
                <li
                  key={index}
                  className={`order-bullet  bullet-${index}`}
                  style={{ left: `${position}%` }}
                >
                  <span >
                    {count}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
  );
};

export default CustomSlider;
