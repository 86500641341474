import React from "react";

const NoWarranties = () => {
  return (
    <div>
        <h2>No Warranties</h2>
        <div className="ced-privacy-list">
        <p>
        UNLESS EXPRESSLY PROVIDED HEREIN, TO THE FULLEST EXTENT PERMITTED BY LAW, CedCommerce.com MAKES NO WARRANTY OR REPRESENTATION OF ANY KIND REGARDING ITS WEBSITE, THE SERVICE, THE PRODUCTS, OR SERVICES AVAILABLE ON THIS WEBSITE AND/OR ANY MATERIALS PROVIDED ON THIS WEBSITE, ALL OF WHICH ARE PROVIDED ON AN AS IS WHERE IS BASIS. CedCommerce.com DOES NOT WARRANT THE ACCURACY, COMPLETENESS, CURRENCY, OR RELIABILITY OF ANY OF THE CONTENT OR DATA FOUND ON THE SERVICE OR THIS WEBSITE. CedCommerce.com EXPRESSLY DISCLAIMS ALL WARRANTIES, TERMS, AND CONDITIONS ABOUT THE SERVICE, INCLUDING ALL IMPLIED WARRANTIES AS TO MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A GENERAL OR PARTICULAR PURPOSE AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS, AND THOSE ARISING BY STATUTE OR OTHERWISE IN LAW OR FROM A COURSE OF DEALING OR USAGE OF TRADE TO THE FULLEST EXTENT PERMITTED BY THE INDIA.
        </p>
        <p>
        CedCommerce.com DOES NOT WARRANT THAT THE SERVICE, THIS WEBSITE, ITS SERVERS, OR ANY EMAIL SENT FROM
        </p>
        <p>
        CedCommerce.com IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
        </p>
        <p>
        FOR THE AVOIDANCE OF DOUBT, CedCommerce.com DOES NOT GUARANTEE THE EFFECTIVENESS OF THE SERVICE.
        </p>
        <p>
        We can also not guarantee that errors in the code or functionality will not cause your account to be suspended or deleted by Etsy.com.
        </p>
        <p>
        CedCommerce.com TAKES NO RESPONSIBILITY FOR THE SECURITY, CONFIDENTIALITY, OR PRIVACY OF THE COMMUNICATIONS AND/OR DATA TRANSMITTED OVER THE INTERNET AND DOES NOT WARRANT (AND EXPRESSLY EXCLUDES ANY AND ALL EXPRESS OR IMPLIED WARRANTIES) THAT THE SERVICE WILL BE WITHOUT FAILURE, DELAY, INTERRUPTION, ERROR OR LOSS OF CONTENT, DATA OR INFORMATION. IN ADDITION, CedCommerce.com SHALL NOT BE LIABLE FOR ANY COMPATIBILITY ISSUES PERTAINING TO CUSTOMERS' TM COMPUTERS, APPLICATIONS, OR OTHER SOFTWARE ON ANY COMPUTERS USING THE SERVICE.
        </p>
        </div>
    </div>
  )
}

export default NoWarranties
