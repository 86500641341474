import React from "react";

const PurposeScope = () => {
  return (
    <div>
        <h2>Purpose and scope</h2>
        <p>
        The Privacy Policy applies primarily to information that we collect online; however, it may apply to some of the data that you provide to us offline and/or through other means, as well (for example, via telephone, or through the mail).
        </p>
        <p>
        The Privacy Policy documents and describes the manner in which our organisation collects information from all our Users/Viewers of our Services. While providing our Services on behalf of our registered users, we collect information related to Viewers. The use of information collected on behalf of our Users is governed primarily by our contract for the engagement with them and the privacy policies governing their organization. We are not accountable for the privacy policies and practices undertaken by our users or any other third parties.
        </p>
    </div>
  )
}

export default PurposeScope
