import React from "react";

const WhatAreCookies = () => {
    return (
        <div>
            <h2>What are Cookies and What Information does it gather?</h2>
            <p>
                Cookies are a small piece of information that is transferred to your computer as soon as you visit a website. We use traffic log cookies for statistical analysis to tailor it to your needs. Once the purpose is achieved, the required data is immediately removed from our system. Cookies further our objective to allow you to take complete advantage of our site You can always change your setting whereby you will be notified whenever a site is using cookies and it will depend on your acceptance whether information is transferred or not. Cookies used by affiliates and partners are not covered under this privacy policy.
            </p>
            <h2>
                There are various types of cookies:
            </h2>

            <div className="ced-privacy-list">
                <p>
                    <span> Strictly necessary cookies:</span>
                    <span>These are cookies that are required for the operation of our Website. For example, cookies that enable users to log into only secure areas of our Website. </span>
                </p>

                <p>
                    <span>Analytical/performance cookies: </span>
                    <span>These types of cookies allow us to recognize, count the number of visitors, and see how visitors move around our Website when they are using it. This assists us to improve the way in which our Website works, for example, by ensuring that you can find what you are looking for easily. </span>
                </p>

                <p>
                    <span> Functionality cookies:</span>
                    <span>These cookies are used to recognize you when you return to our Website. They enable the personalization of content, recognition of users, and also remember your user preferences (for example, your choice of language or region)</span>
                </p>

                <p>
                    <span>Session Cookies:</span>
                    <span>Session cookies exist only during an online session. They disappear from your computer when you close your browser or turn off your computer. We use session cookies to allow our systems to uniquely identify you during a session or while you are logged into the Website or Services. This allows us to process your online transactions and requests and verify your identity, after you have logged in, as you move through our Website or Services.</span>
                </p>
            </div>

        </div>
    )
}

export default WhatAreCookies
