import React, { useState } from "react";
import BannerSection from "../components/BannerSection";
import { privacyData } from "../constant/privacyData";
import useWindowResize from "../utils/useWindowResize";

const PrivacyPolicy = () => {
  const width = useWindowResize();

  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <BannerSection title="Privacy Policy" />
      <section className="ced-container ced-section-space">
        {width.width > 767 ? (
          <div className="ced-vertical-tabs">
            <div className="ced-tabs-menu">
              {privacyData.map((tab, index) => (
                <div
                  key={index}
                  className={`ced-tab-item ${
                    activeTab === index ? "active" : ""
                  }`}
                  onClick={() => setActiveTab(index)}
                >
                  {tab.title}
                </div>
              ))}
            </div>
            <div className="ced-tab-content">
              <div className="ced-content-fade-in">
                {privacyData[activeTab].content}
              </div>
            </div>
          </div>
        ) : (
          <>
            {privacyData.map((tab, index) => (
              <div key={index} className="ced-tab-item">
                <div
                  className={`ced-tab-content ${
                    activeTab === index ? "active" : ""
                  }`}
                >
                  {tab.content}
                </div>
              </div>
            ))}
          </>
        )}
      </section>
    </>
  );
};

export default PrivacyPolicy;
