import React from "react";

const WhoHasAccess = () => {
  return (
    <div>
        <h2>Who has access to your data within our organization?</h2>
        <p>
        Within our organization, access to your data is limited to those persons who require access in order to provide you with the Products and Services, which you purchase from us, to contact you, and to respond to your inquiries, including requests for refund. Those staff members may be on teams such as: marketing, events, development, executive, customer support. Employees only have access to data that is relevant to their team, on a ‘need to know’ basis.
        </p>
    </div>
  )
}

export default WhoHasAccess
