import { BlockStack, Card, InlineGrid, InlineStack, Layout, SkeletonBodyText, SkeletonTabs, SkeletonThumbnail } from '@shopify/polaris';

import React from 'react'
import VariantDetailsSkeleton from './VariantDetailsSkeleton';
import VariantTimelineSkeleton from './VariantTimelineSkeleton';

const EtsyViewSkeleton = () => {

  return (
    <div className="product_view_page_layout">
      <Layout>
        <Layout.Section>
          <BlockStack gap={"300"}>
            <Card roundedAbove='xs'>
              <BlockStack gap={"300"}>
                <InlineGrid
                  columns={{
                    xl: ["oneThird", "twoThirds"],
                    lg: ["oneThird", "twoThirds"],
                    md: ["oneHalf", "oneHalf"],
                  }}
                >
                  <div style={{ "width": "150px" }}>
                    <SkeletonBodyText lines={1} />
                  </div>
                  <SkeletonBodyText lines={4} />
                </InlineGrid>
                <InlineGrid
                  columns={{
                    xl: ["oneThird", "twoThirds"],
                    lg: ["oneThird", "twoThirds"],
                    md: ["oneHalf", "oneHalf"],
                  }}
                >
                  <div style={{ "width": "150px" }}>
                    <SkeletonBodyText lines={1} />
                  </div>

                  <InlineStack gap={"200"}>
                    {Array.from({ length: 10 }, (_, i) =>
                      <div key={i} style={{ "width": "60px" }} className='skeleton-height-20'>
                        <SkeletonBodyText />
                      </div>
                    )}
                  </InlineStack>

                </InlineGrid>
              </BlockStack>
            </Card>
            <Card roundedAbove='xs'>
              <BlockStack gap={"300"}>
                <div style={{ "width": "150px" }}>
                  <SkeletonBodyText lines={1} />
                </div>
                <InlineStack gap={"200"}>
                  {Array.from({ length: 10 }, (_, i) =>
                    <SkeletonThumbnail size='large' key={i} />
                  )}
                </InlineStack>
              </BlockStack>
            </Card>
            <Card roundedAbove='xs'>
              <BlockStack gap={"400"}>
                {Array.from({ length: 3 }, (_, i) =>
                  <InlineGrid
                    key={i}
                    columns={{
                      xl: ["oneThird", "twoThirds"],
                      lg: ["oneThird", "twoThirds"],
                      md: ["oneHalf", "oneHalf"],
                    }}
                  >
                    <div style={{ "width": "100px" }}>
                      <SkeletonBodyText lines={1} />
                    </div>
                    <SkeletonBodyText lines={1} />
                  </InlineGrid>
                )}
              </BlockStack>
            </Card>
            <Card roundedAbove='xs'>
              <BlockStack gap={"400"}>
                <div style={{ "width": "150px" }}>
                  <SkeletonBodyText lines={1} />
                </div>
                {Array.from({ length: 7 }, (_,i) =>
                  <InlineGrid
                  key={i}
                    columns={{
                      xl: ["oneThird", "twoThirds"],
                      lg: ["oneThird", "twoThirds"],
                      md: ["oneHalf", "oneHalf"],
                    }}
                  >
                    <div style={{ "width": "100px" }}>
                      <SkeletonBodyText lines={1} />
                    </div>
                    <div style={{ "width": "80px" }}>
                      <SkeletonBodyText lines={1} />
                    </div>
                  </InlineGrid>
                )}
              </BlockStack>
            </Card>
            <VariantDetailsSkeleton />
            <VariantTimelineSkeleton />
            <Card roundedAbove='xs'>
              <BlockStack gap={"300"}>
                <div style={{ "width": "150px" }}>
                  <SkeletonBodyText lines={1} />
                </div>
                <div className="translation-tab">
                  <SkeletonTabs />
                </div>
                {Array.from({ length: 3 }, (_, i) =>
                  <InlineGrid
                    key={i}
                    columns={{
                      lg: ["oneThird", "twoThirds"],
                      md: ["oneHalf", "oneHalf"],
                    }}
                  >
                    <div style={{ "width": "100px" }}>
                      <SkeletonBodyText lines={1} />
                    </div>
                    <SkeletonBodyText lines={1} />
                  </InlineGrid>
                )}
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap={"300"}>
            <Card roundedAbove='xs'>
              <BlockStack gap={"300"}>
                {Array.from({ length: 3 }, (_,i) =>
                  <InlineGrid
                  key={i}
                    columns={{
                      xl: ["oneThird", "twoThirds"],
                      lg: ["oneThird", "twoThirds"],
                      md: ["oneHalf", "oneHalf"],
                    }}
                  >
                    <div style={{ "width": "100px" }}>
                      <SkeletonBodyText lines={1} />
                    </div>
                    <SkeletonBodyText lines={1} />
                  </InlineGrid>
                )}
              </BlockStack>
            </Card>
            <Card roundedAbove='xs'>
              <BlockStack gap={"300"}>
                {Array.from({ length: 3 }, (_,i) =>
                  <InlineGrid
                   key={i}
                    columns={{
                      xl: ["oneThird", "twoThirds"],
                      lg: ["oneThird", "twoThirds"],
                      md: ["oneHalf", "oneHalf"],
                    }}
                  >
                    <div style={{ "width": "100px" }}>
                      <SkeletonBodyText lines={1} />
                    </div>
                    <div style={{ "width": "150px" }}>
                      <SkeletonBodyText />
                    </div>
                  </InlineGrid>
                )}
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </div>
  )
}

export default EtsyViewSkeleton