import React from "react";

const WhenYouCantClaimRefund = () => {
  return (
    <div>
        <h2>When You Can't Claim The Refund? </h2>
        <ul>
            <li>
            No refund will be initiated for the customization/support work. For cases where sellers receive orders of the wrong items (due to wrong information), refund requests won't be entertained.
            </li>
            <li>
            If within 15 days of the installation of the app, no issues are raised from the sellers, it will be deemed that sellers are familiar with app functionalities and satisfied with its operation, therefore no refund request will be entertained.
            </li>
            <li>
            In case you upgrade or degrade your monthly, or annual plans, it will be automatically managed by Shopify in your upcoming billings. No refund will be initiated in this case.
            </li>
            <li>
             As per the latest updates of Shopify, we will be responsible for all your refund management. Make sure if you are claiming a refund, you must install the app so that we can easily process a refund for you.
            </li>
        </ul>
    </div>
  )
}

export default WhenYouCantClaimRefund
