import React from 'react';
import FaqSection from "../components/FaqSection"
import FeaturesAndBenefits from "../components/FeaturesAndBenefits"
import Hero from "../components/Hero"
import PricingPlan from "../components/PricingPlan"

const Pricing = () => {
  return (
    <>
      <Hero/>
      <FeaturesAndBenefits/>
      <PricingPlan/>
      <FaqSection/>
   </>
  )
}

export default Pricing
