import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store, { persistor } from "./Redux/store";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ShopifyProvider from "./ShopifyAppBridgeProvider";
import NoInternet from "./core/NoInternet";
import { environment } from "./environment/environment";
import { selfSeed } from "./function";
import "@shopify/polaris/build/esm/styles.css";
import { PersistGate } from "redux-persist/integration/react";
import { AppProvider } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import frTranslations from "@shopify/polaris/locales/fr.json";
import { DEFAULT_LANGUAGE } from "./Constants";
import PublicApp from "../public-app/src/PublicApp";
import { PublicAppProvider } from "../public-app/src/contexts/AppProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const isPublicPage = window.location.pathname?.split("/")?.[1] === "public";
const { Api_key } = environment;

!isPublicPage && selfSeed();

const translationsObj: any = {
  [DEFAULT_LANGUAGE]: enTranslations,
  fr: frTranslations,
};

const selectedLanguage = localStorage.getItem("language") ?? DEFAULT_LANGUAGE;

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Routes>
      <Route
        path="/public/*"
        element={
          <PublicAppProvider>
            <PublicApp />
          </PublicAppProvider>
        }
      />
      {!isPublicPage && <Route
        path="*"
        element={
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <AppProvider
                i18n={translationsObj?.[selectedLanguage] ?? enTranslations}
              >
                <ShopifyProvider apiKey={Api_key}>
                  <NoInternet>
                    <App />
                  </NoInternet>
                </ShopifyProvider>
              </AppProvider>
            </PersistGate>
          </Provider>
        }
      />}
    </Routes>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
